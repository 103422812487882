.events_management {

.car--box--events--events:last-child {
    margin-bottom: 0;
}

.car--box--events--events.active {
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #39A2DB;
}

.car-image-area>img {
    height: 120px;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid $input-border-focus;
}

.car-image-area>i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid $input-border-focus;;
}

.car-image-area {
    margin-top: 10px;
    width: 120px;
    text-align: center;
    position: relative;
}

.locatisn {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
}

.locatisn p {
    font-size: 10px;
    line-height: 1.5;
    margin: 0;
    font-weight: 600;
}

.locatisn>i {
    color: #39a2db;
}

.conect-sd {
    width: 90%;
    padding: 3px 10px;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid $input-border-focus;
    border-radius: 5px;
    font-size: 12px;
    color: #c5c5c5;
}

.conect-sd>i {
    font-size: 14px;
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.conect-sd>b {
    font-weight: 500;
    color: #334257;
    font-size: 13px;
}

.car--info h5 span {
    display: block;
    font-size: 12px;
    font-weight: 400;
}

.car--info h5 {
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 10px;
    font-weight: 800;
}

.car--info h5>i {
    color: #FDCB6E;
}

.car--info {
    flex: 1;
}

.spedometer {
    display: inline-flex;
    font-size: 12px;
    background: white;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0 10px;
    align-items: center;
    gap: 5px;
    position: relative;
    top: -12px;
}

[online="1"] .spedometer:before {
    background: #2bb895;
    width: 10px;
}

.spedometer:before {
    content: '';
    height: 10px;
    display: inline-block;
    border-radius: 50%;
}

[online="0"] .spedometer:before {
    background: #D63031;
    width: 10px;
}


.carros-lists {
    overflow-y: scroll;
    height: 100%;
    margin-right: 0;
    padding-right: 0;
}

.carros-lists::-webkit-scrollbar {
    width: 0;
}

.carros-lists::-webkit-scrollbar-track {
    background: transparent;
}

.carros-lists::-webkit-scrollbar-thumb {
    background-color: #334257;
    border-radius: 20px;
}

.color-body{
 color: $color-text-800;
}

}