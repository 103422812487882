#list_index{

  
    .nav-default > li > a {
        background-color: transparent;
        border-color: transparent;
        font-weight: 600;
        font-size: 16px;

        @media only screen and (max-width: 768px) {
            font-size: 11px;
        
        }  
    }
    .nav-default > li.active:after {
        width: 100%;
        height: 5px;

       
    }

    .nav > li > a {
        padding: 20px 15px;
        @media only screen and (max-width: 768px) {
            padding: 10px 15px;
        
        }    
    }

    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
        color:$component-active-bg;
    }
    
    .nav-default {
      
        //background: linear-gradient(180deg, darken(#ffffff, 0), darken(#f2f2f2, 0))
        background:transparent;

    }

    .nav-tabs {
     border-bottom: 0px solid transparent;   
     > li {
     display: table-cell;
     width: 1%;
     float: none;
     > a {
     text-align: center;
     }
    }
    }


    .mobile-px{
        @media only screen and (max-width: 768px) {
    
           margin-left: 5rem !important;
           margin-right: 5rem !important;
        
        }    
        @extend .ms-5;
        @extend .me-5;
    }

    .form-group {
        margin-bottom: 0px !important;
    }

    .table-responsive {
      
        border: none !important;
    }
 
    /* CSS TABLE STYLES */
    
    .table-new {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      
      .table-row {
        width: 100%;
        height: 50px;
        display: flex;
        border-bottom: 1px solid #D9D9D9;
        @media only screen and (max-width: 768px) {
          width: 100vh !important;
        }   
      }
      
      .table-header {
        font-weight: bold;
        background: #EEEEEE;
        border:1px solid #D9D9D9;
        @include box-shadow(0 0px 5px rgba(0,0,0,0.15));
        @include borderRadius(5px);

      }
      
      .table-row div:first-child {
        //width: 18% !important;
      }

      .table-row-column {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }


      .action-buttons{
        height: 40px !important;
        width: 40px !important;
        line-height: 19px;
        font-size: 13px;
        @include box-shadow(0 0px 5px rgba(0,0,0,0.15));
       
     }

     .text-1{
        font-size: 14px;
        font-weight: 600;
        color: #334257;
     }
     .text-0{
      font-size: 12px;
      font-weight: 600;
      color: #334257;
     }
     .text-0-mini{
      font-size: 11px;
      font-weight: 600;
      color: #334257;
     }
     .text-2{
        font-size: 12px;
        font-weight: 500;
        color: #334257;
     }

     .text-header{
        font-size: 13px;
        font-weight: 600;
        color: #334257;
     }

     .color-text{
        color: #334257;
     }

     .text-view-rows{
        font-size: 12px;
        font-weight: 300;
     }
    

     .form-control{
        border:1px solid $component-active-bg !important;
        border-radius: 5px !important;
     }

    .form-control::-moz-placeholder {
        color: #334257 !important;
        opacity: 1; 
    }
      .form-control:-ms-input-placeholder {
        color: #334257 !important;
    }
      .form-control::-webkit-input-placeholder {
        color: #334257 !important;
    }

    .font-report-icon{
     font-size: 30pt;
     color: #334257 !important;
    }

    .bootstrap-select.open > .dropdown-toggle, .bootstrap-select.open > .dropdown-toggle:focus, .bootstrap-select.open > .dropdown-toggle.focus, .bootstrap-select.open > .dropdown-toggle:hover, .bootstrap-select.open > .dropdown-toggle:active, .bootstrap-select.open > .dropdown-toggle.active, .bootstrap-select.open > .dropdown-toggle:active:hover, .bootstrap-select.open > .dropdown-toggle:active:focus, .bootstrap-select.open > .dropdown-toggle:active.focus {
    
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;
  }

  .bootstrap-select > .dropdown-toggle {
    height: 37px !important;
  }


}

.btn-custom-add {
  height: 38px !important;
  width: 38px !important;
  text-align: center !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -khtml-border-radius: 50px !important;
  font-size: 14px;
  line-height: 23px;

}



.modal-success{
  .modal-content{
    background:#00B894 !important;
  }
  .color{
    color:#00B894;
    font-size: 16px;
  }
  .color-error{
    color:red;
    font-size: 16px;
  }
  .error-bg{
    background:red !important;
  }
  .modal-body{
   top:28% !important;
  }
  .check-style{
    font-size: 100pt;
    text-align: center;
    color:white;
  }
  .form-floating input {
    background: #00B894 !important;
    border: white 2px solid !important;
    height: 51px !important;
    font-size: 10pt !important;
    font-weight: 500;
  }
  .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    color: white !important;
    background: #00B894 !important;
  }
  .form-control::-moz-placeholder {
    color: white !important;
    opacity: 1; 
  }
  .form-control:-ms-input-placeholder {
    color: white !important;
  }
  .form-control::-webkit-input-placeholder {
    color: white !important;
  }
  .form-control {
    color:white !important;
  }
  
}


.modal-sidebar{
  .modal-dialog {
    position: fixed !important;
    margin: auto !important;
    width: 400px !important;
    height: 100% !important;
    right: 0px !important;

    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }    
}
.modal-content {
    height: 100% !important;
}

.modal-header .close {
  font-size: 14px;
  margin-top: 6px;
  color:$component-active-bg;
  opacity: 1.0;
}
.modal-header {
  background: transparent;
}

}



.sidebar-filter{
  .text-1{
    font-size: 14px;
    font-weight: 600;
    color: #334257;
 }
 .text-2{
    font-size: 12px;
    font-weight: 500;
    color: #334257;
 }

}


.sidebar-edit{
  .text-1{
    font-size: 14px;
    font-weight: 600;
    color: #334257;
 }
 .text-2{
    font-size: 12px;
    font-weight: 500;
    color: #334257;
 }

 .form-floating input {
  border: $component-active-bg 2px solid !important;
  height: 51px !important;
  font-size: 10pt !important;
  font-weight: 500;
}
.form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  color: $component-active-bg !important;
}



}


.padding-form-create{
  @media only screen and (max-width: 768px) {

  margin-left: 2rem !important;
  margin-right: 2rem !important;
  
  }    
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.padding-form-list{
  @media only screen and (max-width: 768px) {

  margin-left: 2rem !important;
  margin-right: 2rem !important;
  
  }    
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}


.modal-share{
.text-1{
 font-size: 15px;
 color: #334257;
 font-weight: 500;
}
.form-floating input {
  border: $component-active-bg 2px solid !important;
  height: 51px !important;
  font-size: 10pt !important;
  font-weight: 500;
}
.form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  color: $component-active-bg !important;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
}

}



.formulario-reply{

   .btn-holder {
    position:absolute; 
    margin-left:0px;
    left:0%;
    width:100%;
    bottom:20px;
  }

  .btn-holder-relative {
    position:relative; 
    margin-left:0px;
    left:0%;
    width:100%;
    bottom:20px;
  }

  .text-color-1{
    color: #334257;
   }



}

.os-mobile{
@media only screen and (max-width: 768px) {

.title-tab-mobile{
  display: none;
}

.tabs-mobile{
  padding-left: 0px;
  padding-right: 0px;
}



.nav-default > li > a {
  background-color: transparent;
  border-color: transparent;
  font-weight: 300;
  font-size: 10px;

  @media only screen and (max-width: 768px) {
      font-size: 11px;
  
  }  
}
.nav-default > li.active:after {
  width: 100%;
  height: 3px;

 
}

.nav > li > a {
  padding: 10px 10px;
  @media only screen and (max-width: 768px) {
      padding: 10px 15px;
  
  }    
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color:$component-active-bg;
}

.nav-default {

  background: linear-gradient(180deg, darken(#ffffff, 0), darken(#f2f2f2, 0))

}

.nav-tabs {
border-bottom: 0px solid transparent;   
> li {
display: table-cell;
width: 1%;
float: none;
> a {
text-align: center;
}
}
}



}
}


.btn-custom-share {
  height: 38px!important;
  width: 38px!important;
  text-align: center!important;
  border-radius: 50px!important;
  -webkit-border-radius: 50px!important;
  -moz-border-radius: 50px!important;
  -khtml-border-radius: 50px!important;
  font-size: 14px;
  line-height: 23px;
}


