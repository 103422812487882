#smart-center {


    .flex-col {
        flex-direction: column;
    }

    .h-16 {
        height: 4rem;
    }

    .z-30 {
        z-index: 30;
    }

    .shadow-style-cloud {
        box-shadow: 0 0 #0000, 0 0 #0000, 0px 0px 4px #00000014;
    }

    .h-24 {
        height: 10rem;
    }

    .gap-8 {
        gap: 2rem;
    }

    .justify-between {
        justify-content: space-between;
    }

    .w-\[1280px\] {
        width: 1280px;
    }

    .flex {
        display: flex;
    }

    .gap-10 {
        gap: 2.5rem;
    }

    .justify-center {
        justify-content: center;
    }

    .items-center {
        align-items: center;
    }

    .card {
        z-index: auto !important;
        border-radius: 5px;
        padding: 10px;
        padding-left: 40px;
        padding-right: 40px;
        flex: 1;
        align-self: stretch;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    }

    .text-size-subtitle {
        font-size: 24px;
    }

    .font-light {
        font-weight: 300;
    }

    .border-transparent {
        border-color: transparent;
    }

    .border-b-\[3px\] {
        border-bottom-width: 3px;
    }

    .bg-blue-sky {
        background-color: $component-active-bg;
    }

    .rounded-full {
        border-radius: 9999px;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .text-size-highlight {
        font-size: 16px;
    }

    .w-11 {
        width: 4.0rem;
    }

    .h-11 {
        height: 4.0rem;
    }

    .text-size-standard {
        font-size: 12px;
    }

    .rounded-type-round {
        border-radius: 5px;
    }

    .starred-notfound {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 400px;
    }

    .starred-notfound i {
        color: #D9D9D9;
        font-size: 100px;
        margin: 0 auto;
    }

    .starred-notfound-title {
        color: #334257;
        font-size: 16pt;
        font-weight: bold;
        margin: 16px 0;
    }

    .starred-notfound-info {
        color: #666;
        font-weight: 300;
        text-align: center;
    }

    .modal-starred-title {
        color: #334257;
        font-size: 11pt;
        font-weight: 600;
        padding: 5px;
    }

    .modal-starred-inputarea {
        align-items: center;
        display: flex;
        padding: 16px;
    }

    .modal-starred-inputarea input {
        background: $input-bg;
        border: 2px solid $component-active-bg;
        border-radius: 4px;
        height: 48px;
        flex: 1;
        outline: none;
        padding: 0px 8px;
    }

    .modal-starred-inputarea input::placeholder {
        color: #444;
    }

    .modal-starred-inputarea i {
        position: relative;
        right: 32px;
        width: 0;
    }

    .modal-starred-info {
        color: #888;
        font-size: 10pt;
        font-weight: 300;
        padding: 0 16px;
    }

    .modal-starred-info b {
        color: #222;
        font-weight: bold;
    }

    .modal-starred-cars {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;
        //max-height: 400px;
        //overflow-y: auto;
    }

    .starred-car {
        align-items: center;
        border: 1px solid $color-border-100;
        border-radius: 8px;
        box-shadow: 0 0 4px $color-border-100;
        display: flex;
        gap: 16px;
        padding: 24px;
    }

    .starred-slider input[type='checkbox'] {
        display: none
    }

    .starred-slider {
        width: 54px;
        height: 32px;
        z-index: 0;
        margin: 0;
        padding: 0;
        appearance: none;
        border: none;
        cursor: pointer;
        position: relative;
        border-radius: 16px;
        -moz-border-radius: 16px;
        -webkit-border-radius: 16px;
    }

    .starred-slider:before {
        content: ' ';
        position: absolute;
        left: 1px;
        top: 1px;
        width: 54px;
        height: 32px;
        background: #E5E5EA;
        z-index: 1;
        border-radius: 16px;
        -moz-border-radius: 16px;
        -webkit-border-radius: 16px;
    }

    .starred-slider:after {
        content: ' ';
        height: 26px;
        width: 26px;
        border-radius: 50%;
        z-index: 2;
        background: #FFF;
        position: absolute;
        -webkit-transition-duration: 300ms;
        transition-duration: 300ms;
        top: 4px;
        left: 4px;
        /*-webkit-box-shadow: 0 2px 5px #999999;*/
        /*box-shadow: 0 2px 5px #999999;*/
    }

    .starred-slider-on:before {
        background: $component-active-bg;
    }

    .starred-slider-on:after {
        left: 26px !important;
    }

    .starred-car img {
        border-radius: 50%;
        height: 42px;
        object-fit: cover;
        width: 42px;
    }

    .starred-car-name {
        flex: 1;
        font-size: 12pt;
        font-weight: bold;
    }

    .starred-footer {
        display: flex;
    }

    .starred-footer-button {
        @include button-variant-light($component-active-bg, $btn-primary-bg, $btn-primary-border);
        border: none;
        border-radius: 8px;
        font-size: 12px;
        flex: 1;
        margin: 16px;
        padding: 16px;
    }

    .modal-content {
        background: #fff;
        border-radius: 16px;
        padding: 0;
        margin: 0;
        width: 480px;
    }

    .modal-body,
    .modal-footer {
        background: transparent;
        padding: 0;
        margin: 0;
    }

    .conect-sd {
        box-shadow: none !important;
        border: none !important;
    }

    .spedometer {
        top: 0px !important;
    }

    .active {
        background: $component-active-bg !important;
        background: rgba($component-active-bg, 0.2) !important;
        border-color: transparent !important;

        .font-light {
            font-weight: 500;
        }

    }

    .not-padding {
        padding: 0px !important;
    }

    .or-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }

    .or-line {
        height: 1px;
        width: 100%;
        background-color: #f0f0f0;
        margin: 0 10px;
    }

    .or-text {
        padding: 0 10px;
        font-size: 14px;
        color: #777777;
    }

    #barcode-scanner video {
        width: 100%;
        height: 400px;
        object-fit: cover;
        overflow: hidden;
    }

    .card-bar-code {
        padding: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;

    }

    .bg-success {
        background: #00B894 !important;
    }

    .carousel {
        display: flex;
        align-items: center;
    }

    .carousel-item {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-size: cover;
        background-position: center;
        margin-right: 6px;
        position: relative;
        border: 1px solid #BBBBBB;
        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        /* Garante que o cursor indique interatividade */
    }

    .carousel-item:not(.more)::after {
        content: attr(data-tooltip);
        position: absolute;
        bottom: 100%;
        font-weight: 300;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        background-color: $component-active-bg;
        color: white;
        padding: 5px 8px;
        border-radius: 50px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
        margin-bottom: 5px;
        /* Espaço para o caret */

        /* Configurações para truncagem */
        max-width: 150px;
        /* Largura máxima do tooltip */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        /* Mantém o texto em uma única linha */
    }

    .carousel-item::after::before {
        content: "";
        position: absolute;
        top: 100%;
        /* Posicionar abaixo do tooltip */
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border: 5px solid transparent;
        border-top-color: $component-active-bg;
        /* Cor do caret */
    }

    .carousel-item:hover::after {
        opacity: 1;
        visibility: visible;
        /* Torna visível quando mouse passar sobre */
    }

    .carousel-item:not(.more)::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 6%;
        transform: translateX(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }


    .carousel-item.more {
        background: $component-active-bg;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        cursor: pointer;
        font-size: 9px;
        font-weight: 400;
        margin-right: 0;
    }

    /* Specific color classes */
    .dot-color-red::before {
        background-color: #D63031;
    }

    .dot-color-green::before {
        background-color: #00B894;
    }

    .dot-color-blue::before {
        background-color: #FDCB6E;
    }

    .carousel-item.more::before {
        display: none;
    }

    .carousel-item .fa-user {
        font-size: 14px;
        color: #777;
        padding: 7px 8px;
        position: relative;
    }

    .border-top {
        border-top: 1px solid #ccc !important;
    }

    input:checked+.weekdays-slider {
        background-color: #00B894 !important;
        border: 1px solid #00B894 !important;
    }

    @media (max-width: 768px) {

        .hide-mobile {
            display: none !important;
        }



        #btn-collapse {
            display: block !important;
        }
    }



}