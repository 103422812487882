.users-objects {

    #main-change{
        /*background: #f4f5f7 !important*/
        min-height: 100vh;
        padding-top: 0px !important;
    }
    
    .col-xs-10 {
        width: 80.33333% !important;
    }
    
    .newmodal{
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 50%;
        transform: translate(-50%,-50%);
        padding: 20px;
    }
    
    .newtab .tab-content{
        min-height: 20vh;
        max-height: calc(100vh - 200px);
        overflow-x: hidden;
        overflow-y: auto;
    }
    
    .newtab .tab-content::-webkit-scrollbar {
        width: 3px;
    }
    
    .newtab .tab-content::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    .newtab .tab-content::-webkit-scrollbar-thumb {
        background: #d5d5d5;
    }
    
    .newtab .tab-content::-webkit-scrollbar-thumb:hover {
        background: #dadada;
    }
    
    .newtab .tab-head{
        margin-top: 15px;
        @include box-shadow( 0px 5px 10px rgb(224, 224, 224));
        margin-bottom: 15px;
        overflow-x: auto;
        overflow-y: hidden;
        border-radius: 5px;
    }
    
    .newtab .tab-head::-webkit-scrollbar {
        height: 1px;
    }
    
    .newtab .tab-head::-webkit-scrollbar-thumb:hover {
        background: #dadada;
    }
    
    .newtab .tab-footer{
        margin-top: 15px;
        padding-top: 15px;
    }
    
    .newtab .tab-head ul{
        list-style: none;
        display: flex;
        margin: 0px;
        padding: 0px;
    }
    
    .newtab .tab-head ul li a{
        display: block;
        padding: 10px 20px;
        font-size: 1.2rem;
        text-decoration: none;
        text-transform: uppercase;
    }
    
    .newtab .tab-head ul li.active a{
        border-bottom: silver 3px solid;
    }
    
    .newtab .tab-content .tab{
        display: none;
    }
    .newtab .tab-content .tab.open{
        display: block;
    }
    
    .newinput-group{
        margin-bottom: 5px;
    }
    
    .newinput-group .label{
        font-size: 1rem;
        margin-bottom: 5px;
        color: #2c2c2c;
    }
    
    .newinput-group .input input,.newinput-group .input select{
        width: 100%;
        height: 33px;
        border: silver 1px solid;
        border-radius: 5px;
        padding: 5px;
    }
    
    .form-opt{
        display: none !important;
    }
    
    .form-opt.open{
        display: block !important;
    }
    
    .card_margin{
        margin-top: 10px;
        padding-bottom: 0px;
    }
    
    .card{
        border-radius: 5px;
        padding: 10px;
        padding-left: 40px;
        padding-right: 40px;
        flex: 1;
        align-self:stretch;
        @include box-shadow(0px 0px 3px rgba(0,0,0,0.1));
    }
    
    .trackers_info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px;
        margin: 10px 20px 5px;
        background: silver;
        border-radius: 0px 10px 10px 0px;
        color: white;
    }
    
    .failure_bar .failure_top{
        background: #636e72;
        height: 4px;
        border-radius: 2px;
    }
    
    .failure_bar .failure_max{
        text-align: center;
        font-size: 22px;
        font-weight: bold;
    }
    
    .failure_bar .failure_divider{
        margin-top: 2px;
    }
    
    .failure_bar .failure_divider div{
        float: left;
    }
    
    .failure_bar .failure_divider div .bar{
        background: #a2a2a2;
        height: 4px;
        margin-left: 2px;
        margin-right: 2px;
        border-radius: 2px;
        width: calc(100% - 4px);
    }
    
    .failure_bar .failure_divider div:first-child .bar{
        margin-left: 0px;
        width: calc(100% - 2px);
    }
    .failure_bar .failure_divider div:last-child .bar{
        margin-right: 0px;
        width: calc(100% - 2px);
    }
    
    .failure_bar .failure_divider div .text{
        width: 100%;
        padding-top: 5px;
        text-align: center;
        font-size: 18px;
    }
    
    .failure_bar .failure_divider .failure_removal .bar{
        background: #73baff;
    }
    
    .failure_bar .failure_divider .failure_removal .text{
        color: #73baff;
    }
    
    .failure_bar .failure_divider .failure_stopped .bar{
        background: #0a84e3;
    }
    
    .failure_bar .failure_divider .failure_stopped .text{
        color: #0a84e3;
    }
    
    
    .failure_bar .failure_divider .failure_injured .bar{
        background: #6d5be7;
    }
    
    .failure_bar .failure_divider .failure_injured .text{
        color: #6d5be7;
    }
    
    .failure_bar .failure_divider .failure_disconnected .bar{
        background: #a29bff;
    }
    
    .failure_bar .failure_divider .failure_disconnected .text{
        color: #a29bff;
    }
    
    
    .failure_bar .failure_divider .failure_maintenance .bar{
        background: #06cec9;
    }
    
    .failure_bar .failure_divider .failure_maintenance .text{
        color: #06cec9;
    }
    
    .failure_legend{
        display: flex;
        margin-top: 10px;
        flex-wrap: wrap;
    }
    
    .failure_legend .failure_tag{
        display: flex;
        width: 50%;
        margin-bottom: 5px;
    }
    
    .failure_legend .failure_tag .failure_circle{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: silver;
    }
    .failure_legend .failure_tag .failure_text {
        flex: 1;
        margin-left: 5px;
        color: #6e787c;
        font-size: 12px;
    }
    
    .failure_legend .failure_tag.removal .failure_circle{
        background: #73b9ff;
    }
    
    .failure_legend .failure_tag.disconnected .failure_circle{
        background: #a29cfe;
    }
    
    .failure_legend .failure_tag.stopped .failure_circle{
        background: #0984e2;
    }
    .failure_legend .failure_tag.maintenance .failure_circle{
        background: #01cfca;
    }
    .failure_legend .failure_tag.injured .failure_circle{
        background: #6c5ce7;
    }
    
    .hour_label{
        display: flex;
        margin-top: 5px;
    }
    
    .hour_label .hour_circle{
        width: 14px;
        height: 14px;
        background: silver;
        border-radius: 50%;
        margin-top: 3px;
    }
    
    .hour_label .hour_time{
        font-size: 14px;
        color: #6c7579;
        margin-left: 10px;
    }
    
    .hour_label .hour_value{
        font-size: 14px;
        color: #19bf9f;
        margin-left: 10px;
    }
    
    .user_info{
        display: flex;
        font-size: 22px;
        border-bottom: #ebeff2 1px solid;
        padding-bottom: 20px;
        margin: 20px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        align-content: space-between;
    }
    
    .user_info .label{
        color: #667174;
        font-weight: bold;
        text-align: left;
        text-transform: uppercase;
        width: calc(100% - 100px);
        padding: 10px;
    }
    
    .user_info .label a{
        font-weight: normal;
        font-size: 14px;
        text-decoration: none;
        margin-top: 10px;
        display: block;
    }
    
    .user_info .value{
        background: silver;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        margin: 5px;
        line-height: 50px;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
    }
    
    .card.nopad{
        padding: 15px !important;
    }
    
    .toolbox{
        padding: 10px;
        margin-bottom: 20px;
        z-index: 9999;
    }
    
    #menu_btn svg{
        width: 100%;
        height: 100%;
        fill: #1c1c1c;
    }
    
    .feature{
        display: flex;
        margin-top: 15px;
    }
    
    .feature .icon{
        background: #6c5be7;
        color: white;
        font-size: 20px;
        padding: 10px;
        border-radius: 5px;
        padding-top: 8px;
        width: 45px;
        height: 45px;
        text-align: center;
    }
    
    .feature .text{
        margin-left: 5px;
        flex: 1;
    }
    
    .feature .labels{
        color: #636e71;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }
    
    .feature .value{
        color: #636e71;
        font-weight: 500;
        margin-top: -5px;
        font-size: 22px;
    }
    
    .row.colapse{
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s;
    }
    
    div[id*=car] .colapsed{
        display: none;
    }
    
    div[id*=car].show .colapsed{
        display: block !important;
    }
    
    div[id*=car].show .uncolapsed{
        display: none !important;
    }
    
    
    div[id*=car].show .row.colapse{
        max-height: 10000000%;
        padding-top: 20px;
    }
    
    .device_btn{
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
    }
    
    .device_btn button:first-child{
        border-radius: 5px 0px 0px 5px !important;
    }
    
    .device_btn button:last-child{
        border-radius: 0px 5px 5px 0px !important;
    }
    
    .device_sensor{
        border-radius: 5px;
        padding: 8px;
        font-size: 14px;
        @include box-shadow(0px 0px 3px rgba(0,0,0,0.1));
    }
    
    .device_sensor b{
        margin-left: 3px;
    }
    
    .device_sensor i{
        color: #dbdbdb;
    }
    
    .visible-xg-block{
        display: none;
    }
    
    .visible-xxg-block{
        display: none;
    }
    
    .device_row{
        color: #202020;
        font-size: 12px;
        font-weight: 300;
        display: flex;
    }
    
    .device_row .device_col:nth-child(1){
        width: 26px;
    }
    
    .device_row .device_col:nth-child(2) {
        width: 125px;
        margin-left: 40px;
        margin-right: 30px;
    }
    
    .device_row .device_col:nth-child(3) {
        margin-left: 10px;
        flex: 1;
    }
    
    .device_row .device_col:nth-child(4) {
        margin-left: 10px;
        width:180px;
    }
    
    .device_row .device_col:nth-child(5) {
        margin-left: 10px;
        width:160px;
    }
    
    .device_row .device_col:nth-child(6) {
        margin-left: 10px;
        width:160px;
    }
    
    .device_row .device_col:nth-child(7) {
        margin-left: 10px;
        width:140px;
    }
    
    .device_row .device_col:nth-child(8) {
        margin-left: 10px;
        width:60px;
    }
    
    
    div[id*=car] .device_row .device_col:nth-child(3){
        padding-top: 7px;
    }
    
    div[id*=car] .device_row .device_col:nth-child(4),div[id*=car] .device_row .device_col:nth-child(5),div[id*=car] .device_row .device_col:nth-child(6) {
        line-height: 55px;
    }
    
    @media only screen and (max-width: 768px) {
    
        #nav-bar{
            width: 0px;
        }
    
        #menu_btn{
            display: block;
        }
    
        .main{
            margin-left: 0px;
            margin-top: 30px;
            width: 100%;
        }
    
        #menu_act.show #nav-bar{
            width: 77px;
        }
    }
    
    .overlay{
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        background: #334257CC !important;
        z-index: 999;
        left: 0px;
        top: 0px;
        opacity: 1.5;
    }
    
    .overlay_content{
        display: none;
        position: fixed;
        width: 100%;
        z-index: 999999;
        left: 0px;
        top: 0px;
        padding: 20px;
    }
    
    .overlay_close{
        display: none;
        width: 40px;
        height: 40px;
        position: fixed;
        right: 20px;
        bottom: 10px;
        background: white;
        border-radius: 50%;
        padding: 5px;
        font-size: 22px;
        z-index: 99999999;
        text-align: center;
    }
    
    #overlaybox.show .overlay_close{
        display: block;
    }
    
    #overlaybox.show .overlay{
        display: block;
    }
    #overlaybox.show .overlay_content{
        display: block;
    }
    
    #infobox{
        position: absolute;
        z-index: 99999;
        display: none;
        background: white;
        padding: 25px;
        border: #dbdbdb 1px solid;
        border-radius: 10px;
        font-size: 14px;
        @include box-shadow(10px 0px 15px rgba(0, 0, 0, 0.1));
        width: 400px;
    }
    
    .device_address{
        font-size: 20px;padding-top: 10px;display: flex;
    }
    
    .device_row_a1{
        padding-top: 10px;
    }
    
    .device_row_a2{
        margin-top: 30px;
    }
    
    .device_col_a3{
        margin-bottom: 30px;
    }
    
    .promptBox .plan{
        position: fixed;
        left: 50%;
        top:50%;
        transform:translate(-50%,-50%);width: 30%;
    }
    
    .toolsBox{
        display: none;
        position: absolute;
        background: white;
        @include box-shadow(0px 0px 15px rgba(0,0,0,0.25));
        border-radius: 12px;
        z-index: 999999;
    }
    
    .toolsBox ul{
        list-style: none;
        padding: 0px;
        margin: 0px;
    }
    
    .toolsBox ul li:hover {
        background: #dae6f6 !important;
    }
    
    .toolsBox ul li a {
        display: block;
        padding: 5px 10px;
        color: #444;
        text-decoration: none;
    }
    
    .toolsBox ul li a i {
        color: #444;
        font-size: 16px;
        margin-right: 10px;
    }
    
    @media only screen and (max-width: 1440px) {
    
        .device_row_a1{
            padding-top: 0px;
        }
    
        .device_row_a2{
            margin-top: 5px;
        }
    
        .device_sensor{
            padding: 5px;
        }
    
        .device_col_a3{
            margin-bottom: 25px;
        }
    
        .device_address{
            font-size: 14px;
        }
        
        .device_btn button{
            font-size: 9px;
            padding: 10px 6px;
        }
    
    }
    
    @media only screen and (max-width: 1200px) {
        .device_row .device_col:nth-child(5) {
            display: none !important;
        }
    }
    
    @media only screen and (max-width: 1150px) {
        .device_row .device_col:nth-child(6) {
            display: none !important;
        }
    }
    
    @media only screen and (max-width: 768px) {
    
        .hide-mobile {
            display: none;
        }
    
        .btn.act i {
            font-size: 20px;
            margin: 5px 0px;
        }
    
        .device_row .device_col:nth-child(4) {
            display: none !important;
        }
    
        .newmodal {
            position: absolute;
            left: 0%;
            top: 0%;
            width: 100%;
            /*height: 100%;*/
            transform: translate(0%,0%);
            padding: 20px;
        }
    
    }
    
    @media only screen and (max-width: 425px) {
        .promptBox .plan {
            position: fixed;
            left: 50%;
            top:50%;
            width: 80%;
            transform:translate(-50%,-50%);
        }
    }
    
    #all-users ul,#all-estoque ul,#all-tecnicos ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
    }
    
    #all-users ul li,#all-estoque ul li,#all-tecnicos ul li {
        padding: 8px;
        border-bottom: silver 1px dotted;
    }
    
    #all-users ul li.selected,#all-estoque ul li.selected,#all-tecnicos ul li.selected {
        background: rgba(10, 132, 227, 0.1);
    }
    
    #all-users ul li:hover,#all-estoque ul li:hover,#all-tecnicos ul li:hover {
        background: #dae6f6;
    }
    
    #inner {  
        border: 0.05em solid black;
    }
    
    #outer {
        border: 0.05em solid red;
        width:100%;
        display: flex;
        justify-content: center;
    }
    
    @keyframes rotation {
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
    }
    
    .btnSync {  
        background: none;
        color: rgb(219, 219, 219);
        font-size: 11px;
        width: 20px;
        height: 20px;
        padding: 0px;
        border-radius: 50%;
        border: none;
    }
    
    .btnSync.syncLoading i {
        color: rgb(49, 49, 49);        
        animation: rotation 2s infinite linear;
    }    

}