.videoOverlay {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;

}


.videoPlayer {
    width: 70vw;
    height: calc(70vw * 0.5625);
    background: black;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 99999999;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
}

#smartcam {
    margin-left: -15px;
    margin-right: -15px;


    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn a,
        .count span {
            display: block;
            background: white;
            border-radius: 5px;
            padding: 5px;
        }
    }


    h4 {
        font: normal normal bold 16px/19px Lato;
        color: #334257;
    }

    .header {
        display: flex;
        background: white;

        .title {
            font: normal normal bold 24px/29px Lato;
            font-size: 22px;
            padding: 20px;
            color: #334257
        }
    }

    .content {
        background: rgba(255, 255, 255, 0.25);
        margin-top: 2px;
        padding: 32px;
    }

    .event-list {
        border-top: #D9D9D9 2px solid;


        #filters {
            display: flex;

            .filter {
                font-size: 14px;
                font-weight: 500;
                padding: 7px;
                border-radius: 3px;
                cursor: pointer;
                margin-right: 5px;
                position: relative;
                display: flex;
                align-items: center;
                user-select: none;

                i {
                    margin-right: 5px;
                }

                .counter {
                    background: rgb(233, 8, 8);
                    color: white;
                    font-size: 9px;
                    padding: 3px;
                    margin-right: 5px;
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    line-height: 14px;
                    text-align: center;
                    overflow: hidden;

                    &:empty {
                        display: none !important;
                    }
                }

                .submenu {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 28px;
                    background: white;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    width: auto;
                    z-index: 99999999;

                    ul {
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        max-height: 300px;
                        overflow: auto;

                        li {
                            font-size: 12px;
                            display: flex;
                            white-space: nowrap;
                            padding: 10px;
                            position: relative;

                            input {
                                margin-right: 10px;
                            }

                            &:hover {

                                background: rgba(0, 0, 0, 0.025);
                            }

                            &:after {
                                position: absolute;
                                content: " ";
                                left: 0px;
                                top: 0px;
                                width: 100%;
                                height: 100%;
                                background: transparent;
                            }
                        }
                    }
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.025);

                    .submenu {
                        display: block;
                    }
                }
            }
        }

        .event {
            padding: 15px 20px;

            .img {
                background-size: cover;
                background-position: center;
                width: 353px;
                height: 202px;
                border-radius: 10px;
                margin-top: 5px;
                margin-bottom: 5px;
                position: relative;
                cursor: pointer;
                overflow: hidden;

                .play {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 60px;
                    height: 60px;
                    background: rgba(0, 0, 0, 0.60);
                    border-radius: 50%;
                    border: white 1px solid;
                    color: white;
                    padding: 10px;
                    font-size: 25px;
                    transform: translate(-50%, -50%);
                    z-index: 3;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:after {
                    content: " ";
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.40);
                    z-index: 2;
                }
            }


            .map {
                background: silver;
                width: 426px;
                height: 202px;
                border-radius: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            .info {
                margin-left: 20px;
                margin-right: 20px;
                flex: 1;

                h5 {
                    font: normal normal bold 15px/18px Lato;
                    color: #334257;
                    margin: 0px;
                }
            }
        }
    }


    .connect_sd {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-10)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-lato);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--amarelo-detalhes-);
        text-align: center;
        font: normal normal 600 10px/12px Lato;
        letter-spacing: 0px;
        color: #FDCB6E;
        text-transform: uppercase;
        opacity: 1;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.50);
        border-radius: 10px;
        padding: 5px;
    }

    .device {
        display: flex;
        margin-top: 20px;

        .device_img {
            background-size: cover;
            background-position: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
        }

        .name {
            font-weight: 600;
            font-size: 14px;
        }

        .model {

            margin-bottom: 15px;
        }

        .speed {
            font-weight: 600;
            font-size: 15px;
            margin-top: 5px;

            i {
                margin-right: 10px;
            }
        }

        .address {
            font-size: 14px;
            margin-top: 5px;

            i {
                margin-left: 3px;
                margin-right: 7px;
            }
        }
    }


}