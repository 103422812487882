@mixin panel-heading-vertical-align($element-height) {
  margin-top: (($heading-height - $element-height) / 2);
  margin-bottom: (($heading-height - $element-height) / 2);
}

.panel {
  .panel-heading {
    padding: 0;
    @include clearfix;
    font-weight: 700;
  }
  .panel-title {
    @include text-overflow;
  }
  .panel-title,
  .panel-title-overflow {
    padding: $panel-heading-padding;
    font-size: $panel-title-size;


    .icon {
      font-size: $panel-title-icon-size;
      color: $panel-title-icon-color;

      vertical-align: middle;
    }

    @media (min-width: $grid-float-breakpoint) {
      float: left;
    }
  }
  .close + .panel-title {
    max-width: 95%;
  }
}

.panel-transparent {
  margin-bottom: 0;
  @include box-shadow(none);
}

.panel-form {
  padding: 10px $navbar-padding-horizontal;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  @include form-inline;

  //overwrite include form-inline width auto
  .form-control {
    width: 100%;
  }

  &.panel-form-right {
    float: right;
    padding: 0;
    max-width: 120px;
  }

  .form-group {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }



  // Undo 100% width for pull classes
  @media (min-width: $grid-float-breakpoint) {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;

    @include panel-heading-vertical-align($input-height-base);
    float: left;
  }
}