.thumbnail-preview {
  position: relative;
  z-index: 0;
  cursor: pointer;

  >.icon {
    font-size: 16px;
    padding: 2px;
  }

  .full-preview {
    position: absolute;
    background-color: white;
    padding: 5px;
    right: 15px;
    top: 15px;
    border: 1px solid gray;
    visibility: hidden;
    color: black;
    text-decoration: none;

    img {
      max-width: 400px;
      max-height: 400px;
    }
  }

  &:hover {
    background-color: transparent;
    z-index: 50;

    .full-preview {
      visibility: visible;
    }
  }
}
