.modal-scrollable {
  position: relative;
}

.modal-content {
  background-color: $modal-content-bg;
}

.modal-header {
  background: $modal-header-bg;

  .close {
    font-size: 25px;
    margin-top: -3px;
  }
}

.modal-title {
  .icon {
    vertical-align: top;
    color: $brand-primary;
  }
}

.modal-body {
  >.nav {
    margin: -($modal-inner-padding);
    margin-bottom: $modal-inner-padding;
  }

  &.no-padding {
    padding: 0;
  }
}

.modal-footer {
  background: $modal-footer-bg;
  text-align: left;
}

$modal-md: 760px;

@media (min-width: $screen-sm-min) {
  .modal-md {
    width: $modal-md;
  }
}

.modal-full {
  width: auto;
  margin: 10px;
}

.modal {
  &.fade .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }

  &.in .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

.modal-popup {

  position: fixed;

  &.center {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &.top_left {
    top: -1px;
    left: 0;
    margin-left: 20px;
  }

  &.top_right {
    top: -1px;
    right: 0;
    margin-right: 20px;
  }

  &.bottom_left {
    bottom: -1px;
    left: 0;
    margin-left: 20px;
  }

  &.bottom_right {
    bottom: -1px;
    right: 0;
    margin-right: 20px;
  }

  .modal-dialog {
    margin-bottom: 0;
    margin-top: 0;

    width: auto;
    min-width: 300px;
    max-width: 100%;




  }

  .modal-content {
    background-color: $modal-header-bg;

    >.close {
      margin-top: 0;
      margin-right: 3px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      opacity: 0.9;

      font-weight: 300;
      font-size: 40px;
      color: $color-text-800;
    }
  }

  .modal-header {
    background-color: $modal-content-bg;
    padding: $modal-title-padding $modal-title-padding*2;
    border-bottom: 1px solid $color-border-100;

    .close {
      margin-top: 0;
      margin-right: 3px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .modal-body {
    padding: $modal-inner-padding $modal-inner-padding*3;
  }

}

.dashboard {
  .modal-content {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .modal-header {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.modal-starred {

  .starred-notfound {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
  }

  .starred-notfound i {
    color: #D9D9D9;
    font-size: 100px;
    margin: 0 auto;
  }

  .starred-notfound-title {
    color: #334257;
    font-size: 16pt;
    font-weight: bold;
    margin: 16px 0;
  }

  .starred-notfound-info {
    color: #666;
    font-weight: 300;
    text-align: center;
  }

  .modal-starred-title {
    color: #334257;
    font-size: 11pt;
    font-weight: 600;
    padding: 5px;
  }

  .modal-starred-inputarea {
    align-items: center;
    display: flex;
    padding: 16px;
  }

  .modal-starred-inputarea input {
    background: $input-bg;
    border: 2px solid $component-active-bg;
    border-radius: 4px;
    height: 48px;
    flex: 1;
    outline: none;
    padding: 0px 8px;
  }

  .modal-starred-inputarea input::placeholder {
    color: #444;
  }

  .modal-starred-inputarea i {
    position: relative;
    right: 32px;
    width: 0;
  }

  .modal-starred-info {
    color: #888;
    font-size: 10pt;
    font-weight: 300;
    padding: 0 16px;
  }

  .modal-starred-info b {
    color: #222;
    font-weight: bold;
  }

  .modal-starred-cars {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    max-height: 400px;
    overflow-y: auto;
  }

  .starred-car {
    align-items: center;
    border: 1px solid $color-border-100;
    border-radius: 8px;
    box-shadow: 0 0 4px $color-border-100;
    display: flex;
    gap: 16px;
    padding: 24px;
  }

  .starred-slider input[type='checkbox'] {
    display: none
  }

  .starred-slider {
    width: 54px;
    height: 32px;
    z-index: 0;
    margin: 0;
    padding: 0;
    appearance: none;
    border: none;
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
  }

  .starred-slider:before {
    content: ' ';
    position: absolute;
    left: 1px;
    top: 1px;
    width: 54px;
    height: 32px;
    background: #E5E5EA;
    z-index: 1;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
  }

  .starred-slider:after {
    content: ' ';
    height: 26px;
    width: 26px;
    border-radius: 50%;
    z-index: 2;
    background: #FFF;
    position: absolute;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    top: 4px;
    left: 4px;
    /*-webkit-box-shadow: 0 2px 5px #999999;*/
    /*box-shadow: 0 2px 5px #999999;*/
  }

  .starred-slider-on:before {
    background: $component-active-bg;
  }

  .starred-slider-on:after {
    left: 26px !important;
  }

  .starred-car img {
    border-radius: 50%;
    height: 42px;
    object-fit: cover;
    width: 42px;
  }

  .starred-car-name {
    flex: 1;
    font-size: 12pt;
    font-weight: bold;
  }

  .starred-footer {
    display: flex;
  }

  .starred-footer-button {
    @include button-variant-light($component-active-bg, $btn-primary-bg, $btn-primary-border);
    border: none;
    border-radius: 8px;
    font-size: 12px;
    flex: 1;
    margin: 16px;
    padding: 16px;
  }

  .modal-content {
    background: #fff;
    border-radius: 16px;
    padding: 0;
    margin: 0;
    width: 480px;
  }



  .modal-body,
  .modal-footer {
    background: transparent;
    padding: 0;
    margin: 0;
  }

}

.modal-mobile {


  @extend .mobile-px;

  .modal-content {
    border-radius: 10px !important;
    border: transparent;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;

    @media only screen and (max-width: 768px) {
      width: 350px !important;
    }

  }

  .modal-title {
    text-align: center !important;
    font-size: 17px;
    font-weight: 600;
    margin-left: 10%;
  }

  .modal-header {
    background: transparent !important;

    .close {
      font-size: 25px !important;
      margin-top: -5px !important;
      height: 38px !important;
      width: 38px !important;
      text-align: center !important;
      border-radius: 50px !important;
      -webkit-border-radius: 50px !important;
      -moz-border-radius: 50px !important;
      -khtml-border-radius: 50px !important;
      line-height: 23px;
      font-weight: 300 !important;
      border: 1px solid #cccccc !important;
      color: #a19d9d !important;
      @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
      opacity: 1.0;


    }
  }

  .modal-footer {
    background: transparent !important;
    text-align: left !important;
  }


}

/*modal fullscreen */

.modal.modal-fullscreen {
  /* Maximize the main wrappers on the screen */
  /* Make the parent wrapper of the modal box a full-width block */
  /* Remove borders and effects on the content */
  /**
	 * /!\ By using this feature, you force the header and footer to be placed
	 * in an absolute position. You must handle by yourself the margin of the
	 * content.
	 */

  .modal-header {
    background: transparent !important;

    .close {
      font-size: 25px !important;
      margin-top: -5px !important;
      height: 38px !important;
      width: 38px !important;
      text-align: center !important;
      border-radius: 50px !important;
      -webkit-border-radius: 50px !important;
      -moz-border-radius: 50px !important;
      -khtml-border-radius: 50px !important;
      line-height: 23px;
      font-weight: 300 !important;
      border: 1px solid #cccccc !important;
      color: #a19d9d !important;
      @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
      opacity: 1.0;


    }
  }
}

.modal.modal-fullscreen .modal-dialog,
.modal.modal-fullscreen .modal-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal.modal-fullscreen .modal-dialog {
  margin: 0;
  width: 100%;
  animation-duration: 0.6s;
}

.modal.modal-fullscreen .modal-content {
  border: none;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: inherit;
  -moz-box-shadow: inherit;
  -o-box-shadow: inherit;
  box-shadow: inherit;
  /* change bg color below */
  /* background:#1abc9c; */
}

.modal.modal-fullscreen.force-fullscreen {
  /* Remove the padding inside the body */
}

.modal.modal-fullscreen.force-fullscreen .modal-body {
  padding: 0;
}

.modal.modal-fullscreen.force-fullscreen .modal-header,
.modal.modal-fullscreen.force-fullscreen .modal-footer {
  position: absolute;
  bottom: 0;
}

.modal.modal-fullscreen.force-fullscreen .modal-header {
  top: 0;

}


.modal-footer-fullscreen {
  width: 100% !important;
  position: absolute !important;
  bottom: 0 !important;
  background-color: #FFF !important;
}