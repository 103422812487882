label {
  font-weight: normal;

  &.required {
    &:after {
      content: '*';
      padding-left: 5px;
    }
  }
}

textarea.form-control {
  resize: none;
}

.form-horizontal {
  .control-label {
    text-align: left;
  }

  .radio,
  .checkbox {
    min-height: 0;
    padding-top: 0;
  }

  .checkboxes {

    .checkbox,
    .radio {
      margin-bottom: 10px;
    }
  }
}

.form-horizontal .has-feedback,
.has-feedback {
  >.form-control:last-child {
    padding: $padding-base-vertical $padding-base-horizontal;
    padding-left: $input-height-base;
  }

  >.form-control-feedback {
    left: 0;
    right: auto;
    z-index: 3;
  }

  >.form-control {
    +.form-control-feedback {
      left: auto;
      right: 0;
      z-index: 2;
    }
  }
}

.form-group.search,
.bs-searchbox {
  & {
    position: relative;
  }

  &:after {
    @extend .form-control-feedback;
    @extend .fa;
    @extend .fa-search:before;

    line-height: $input-height-base;
  }

  >.form-control {
    padding-right: $input-height-base;
  }
}

.bootstrap-select {
  $color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);

  >.dropdown-toggle {
    color: $input-color;
    background-color: $input-bg;
    //border: 1px solid $input-border;
    //border-radius: $input-border-radius;
    box-shadow: none;
    border-radius: 10px !important;
    border: 0px solid transparent !important;
    @include transition(border-color ease-in-out .15s);

    &:focus,
    &.focus,
    //&:hover,
    &:active,
    &.active {
      color: $input-color-focus;
      background-color: $input-bg-focus;
      border-color: $input-border-focus;
      outline: 0;
      @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $color-rgba);
    }
  }

  &.open {
    >.dropdown-toggle {

      &,
      &:focus,
      &.focus,
      &:hover,
      &:active,
      &.active,
      &:active:hover,
      &:active:focus,
      &:active.focus {
        color: $input-color-focus;
        background-color: $input-bg-focus;
        border-color: $input-border-focus;
        outline: 0;
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $color-rgba);
      }
    }
  }
}

.panel .panel-form .form-control {
  outline: 0;
}

.panel-default .panel-form .form-control {
  $color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);

  color: $input-color-focus;
  background-color: $input-bg-focus;
  //border-color: $input-border;
  //@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
}

.checkboxes {
  @extend .row;

  .checkbox {
    @extend .col-sm-6;
    @extend .col-xs-6;

    margin-top: 0;
  }

  .checkbox+.checkbox {
    margin-top: inherit;
  }
}

.form-image {
  position: relative;
  min-height: 200px;
  text-align: center;
  border: 1px solid $color-border-100;

  .form-image-controls {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    max-height: 100%;
  }

  img+.no-image {
    display: none;
  }
}

.help-block {
  @extend .alert;

  &.error {
    @extend .alert-danger;
  }
}

.explanation {
  font-size: $font-size-smaller;
}

.form-floating {
  position: relative;

  label {
    color: rgb(143, 158, 171) !important;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    transform: translate(0.3rem, 0.8rem);
  }

  input {
    width: 100%;
    height: 45px !important;
    border: $input-border-focus 1px solid !important;
    background: $input-bg !important;
    // font-size: 13pt !important;
    text-align: left !important;

    &::placeholder {
      color: $input-bg !important;
    }
  }

  .error_msg {
    display: none;
    background: white;
    color: red;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3px;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    transform: translateY(10px) translateX(1.3rem);
  }

  &.error {
    .error_msg {
      display: block;
    }

    input {
      border: red 2px solid !important;

      &:focus {
        border: #202020 2px solid !important;
      }
    }
  }


  .options {
    position: absolute;
    left: 0px;
    top: 46px;
    opacity: 0;
    background: white;
    max-height: 0px;
    overflow: hidden;
    border-radius: 10px;
    transition: max-height 0.5s ease-in-out, opacity 0.5s;
    border: silver 1px solid;
    z-index: 99999;
    min-width: 100%;

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        padding: 8px;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }




  &>.form-control-plaintext~label,
  &>.form-control:focus~label,
  &>.form-control:not(:placeholder-shown)~label,
  &>.form-select~label {
    color: $input-color !important;
    transform: scale(.95) translateY(-1.5rem) translateX(1.3rem) !important;
    background: transparent !important;
    text-shadow: 1px 1px 0 $input-bg,
      -1px -1px 0 $input-bg,
      1px -1px 0 $input-bg,
      -1px 1px 0 $input-bg;


  }

  &>.form-control:focus~.options {
    opacity: 1 !important;
    max-height: 30vh !important;
    overflow: auto !important;
    transition: max-height 0.5s ease-in-out, opacity 0.3s !important;
  }

}