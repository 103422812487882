#app-clients-index {
    #sidebar {
        overflow: hidden;
        z-index: 3;
        height: 100vh !important;
        background-color: #333;
    }

    #sidebar .list-group {
        /* min-width: 400px; */
        background-color: #333;
        min-height: 100vh;
    }

    #sidebar i {
        margin-right: 6px;
    }

    #sidebar .list-group-item {
        border-radius: 0;
        background-color: #333;
        color: #ccc;
        border-left: 0;
        border-right: 0;
        border-color: #2c2c2c;
        white-space: nowrap;
    }

    /* highlight active menu */

    #sidebar .list-group-item:not(.collapsed) {
        background-color: #222;
    }

    /* closed state */

    #sidebar .list-group .list-group-item[aria-expanded="false"]::after {
        content: " \f0d7";
        font-family: FontAwesome;
        display: inline;
        text-align: right;
        padding-left: 5px;
    }

    /* open state */

    #sidebar .list-group .list-group-item[aria-expanded="true"] {
        background-color: #222;
    }

    #sidebar .list-group .list-group-item[aria-expanded="true"]::after {
        content: " \f0da";
        font-family: FontAwesome;
        display: inline;
        text-align: right;
        padding-left: 5px;
    }

    /* level 1*/

    #sidebar .list-group .collapse .list-group-item,
    #sidebar .list-group .collapsing .list-group-item {
        padding-left: 20px;
    }

    /* level 2*/

    #sidebar .list-group .collapse>.collapse .list-group-item,
    #sidebar .list-group .collapse>.collapsing .list-group-item {
        padding-left: 30px;
    }

    /* level 3*/

    #sidebar .list-group .collapse>.collapse>.collapse .list-group-item {
        padding-left: 40px;
    }

    .main {
        overflow: auto;
        height: 100%;
        padding: .5rem;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
    }

    .collapse.show {
        visibility: visible;
    }

    .collapsing {
        visibility: visible;
        height: 0;
        -webkit-transition-property: height, visibility;
        transition-property: height, visibility;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    .collapsing.width {
        -webkit-transition-property: width, visibility;
        transition-property: width, visibility;
        width: 0;
        height: 100%;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    .foreground {
        color: white;
        padding: 30px;
    }

    .foreground-mobile {
        color: white;
        padding: 15px;
    }

    .btn-image {
        min-width: 100px;
        width: 20vw;
    }

    .preview-content {
        padding: 30px;
    }

    .image-preview-container {
        /* background-image: url("/image/transparent.png"); */
        //background-color: #e0e0e0;
        //background-position: center;
        //background-repeat: no-repeat;
        //background-size: cover;
        //width: 61vw;
        //padding-bottom: 30px;
    }



    .download-button {
        float: right;
        margin-top: -55px;
    }

    .display-none-825 {
        display: none;
    }

    .color-picker-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media(min-width: 825px) {
        .display-none-825 {
            display: block;
        }

        .descrip-button {
            text-align: center;
            padding-top: 10px;
            position: absolute;
            left: calc(50% - 175px);
        }

        .description {
            float: right;
            padding-left: 5px;
            padding-top: 5px;
            padding-right: 22px;
        }

        .fa-icon {
            font-size: xx-large
        }

        .fa-icon-apple {
            font-size: xx-large;
            float: right;
        }
    }

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 4px !important;
        border-radius: 10px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px !important;
        height: 15px !important;
        border-radius: 50%;
        color: $component-active-bg;
        background: $component-active-bg !important;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $component-active-bg;
        cursor: pointer;
    }

    .outputs-image-overlay {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
    }

    .outputs-image-overlay svg {
        width: 100%;
        height: 100%;
        stroke-width: 1px;
        stroke: rgba(0, 0, 0, .12);
    }

    .output-main {
        text-align: center;
    }

    .output-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .label-row-4 {
        padding-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        text-align: center;
        font-family: 'Roboto Mono', monospace;
        color: rgba(0, 0, 0, .38);
        font-size: 13px;
    }

    .hr-line {
        border-color: white;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .background {
        color: white;
        padding: 30px;
    }

    .background-mobile {
        color: white;
        padding: 15px;
    }

    .general {
        color: white;
        padding: 30px;
    }

    .color {
        padding-top: 20px;
    }

    .color-mobile {
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .color-picker {
        cursor: pointer;
        display: none;
    }

    .color-picker-icon {
        padding-top: 2.5px;
        max-width: 25px;
        max-height: 25px;
        cursor: pointer;
        margin-left: 15px;
    }

    .crosshair {
        cursor: crosshair;
    }

    .default-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .color-picker-div {
        background-color: #ffffff;
        width: 100%;
        height: 38px;
        cursor: pointer;
        text-align: center;
        padding: 5px 0px;
    }

    .color-picker-div-full {
        width: 30px !important;
        height: 30px !important;
        text-indent: -10000;
        background-color: #ffffff;
        border-radius: 24px !important;
        border: 2px solid #e9ecef;
    }

    .color-picker-pick-div {
        width: 15%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
    }

    .color-picker-pick-i {
        font-size: 20px;
        padding-top: 5px;
        padding-left: 20px;
    }

    .pickemall-overlay {
        position: absolute;
        width: 44px;
        height: 30px;
        display: block;
    }

    .color-picker-word {
        cursor: text;
        width: fit-content;
        margin: auto;
        margin-top: 0px !important;
    }

    .phone-row {
        text-align: center;
    }

    .label-row-6 {
        text-align: center;
        padding-top: 30px;
        font-family: 'Roboto Mono', monospace;
        color: rgba(0, 0, 0, .38);
        font-size: 13px;
    }

    .display-none {
        display: none;
    }

    .light {
        color: #000;
    }

    .dark {
        color: #fff;
    }

    .preview-phone {
        height: 200px;
    }

    .grid-control {
        margin-left: 20px;
        margin-bottom: 5px;
    }

    .name-icon {
        margin-top: 20px;
    }

    .size-512 {
        width: 512px;
        height: 512px;
    }

    .martop_marleft {
        margin-top: 8px;
        margin-left: 8px;
    }

    .modal-header {
        border-bottom: 0px solid #e9ecef;
    }

    .modal-dialog {
        height: 70%;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .modal-content {
        margin: 0 auto;
        background-color: rgba(255, 255, 255, .95);
    }

    .gif-img {
        width: 200px;
    }

    .text-center {
        text-align: center;
    }

    .modal-text {
        width: 40vw;
    }

    .dimgrey {
        color: dimgrey;
    }

    .darkslategrey {
        color: darkslategrey;
    }

    .padding-top-20 {
        padding-top: 20px;
    }

    .padding-bottom-10 {
        padding-bottom: 10px;
    }

    .btn-ios-adaptive {
        width: inherit;
    }

    .btn-ios-legacy {
        width: inherit;
    }

    .left-card {
        border-radius: 0px .25rem .25rem 0px;
    }

    .left-bottom {
        text-align: center;
        position: relative;
        padding-top: 30px;
    }

    .banner {
        max-width: 24vw;
    }

    .banner-mobile {
        max-width: 50vw;
    }

    .breif-description {
        padding-top: 5px;
        text-align: center;
    }

    .detail {
        text-align: center;
    }

    .detail-title {
        margin-top: 25px;
        color: #757575;
    }

    .detail-center {
        text-align: center;
    }

    .detail-img {
        width: 80px;
    }

    .detail-row {
        margin-top: 30px;
    }

    .detail-padding {
        margin-top: 15px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .footer {
        color: black;
        padding-left: 30px;
    }

    .footer-a {
        color: #757575;
    }

    #file-upload-image {
        position: absolute;
        z-index: -1;
    }

    input[type="color"] {
        -webkit-appearance: none;
        border: none;
        padding: 0px;
    }

    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    input[type="color"]::-webkit-color-swatch {
        border: none;
    }

    /* ------------------------------------------------------- */

    .bmc-button img {
        width: 27px !important;
        margin-bottom: 1px !important;
        box-shadow: none !important;
        border: none !important;
        vertical-align: middle !important;
    }

    .bmc-button {
        line-height: 34px !important;
        height: 37px !important;
        text-decoration: none !important;
        display: inline-block;
        color: #ffffff !important;
        background-color: #FF813F !important;
        border-radius: 3px !important;
        border: 1px solid transparent !important;
        padding: 1px 9px !important;
        font-size: 22px !important;
        letter-spacing: 0.6px !important;
        box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
        -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
        font-family: 'Cookie', cursive !important;
        -webkit-box-sizing: border-box !important;
        box-sizing: border-box !important;
        -o-transition: 0.3s all linear !important;
        -webkit-transition: 0.3s all linear !important;
        -moz-transition: 0.3s all linear !important;
        -ms-transition: 0.3s all linear !important;
        transition: 0.3s all linear !important;
    }

    .bmc-button:hover,
    .bmc-button:active,
    .bmc-button:focus {
        -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
        text-decoration: none !important;
        box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
        opacity: 0.85 !important;
        color: #ffffff !important;
    }

    .btn-facebook-share {
        color: #ffffff;
        text-transform: uppercase;
        font-family: 'Cookie', cursive !important;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.5px;
        transition: 0.3s all linear;
        -moz-transition: 0.3s all linear;
        -webkit-transition: 0.3s all linear;
        -ms-transition: 0.3s all linear;
        -o-transition: 0.3s all linear;
        box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
        -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
        padding: 7px 17px !important;
        height: 37px !important;
        margin-left: 7px;
        display: inline-block;
        border-radius: 3px;
        background-color: #3B5998;
    }

    .btn-facebook-share:hover {
        color: #ffffff;
        background-color: #467AC9;
    }

    .btn-twitter-tweet {
        color: #ffffff;
        text-transform: uppercase;
        font-family: 'Cookie', cursive !important;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.5px;
        transition: 0.3s all linear;
        -moz-transition: 0.3s all linear;
        -webkit-transition: 0.3s all linear;
        -ms-transition: 0.3s all linear;
        -o-transition: 0.3s all linear;
        box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
        -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
        padding: 7px 17px !important;
        height: 37px !important;
        display: inline-block;
        border-radius: 3px;
        margin-left: 7px;
        background-color: #1da1f2;
    }

    .btn-twitter-tweet:hover {
        color: #ffffff;
        background-color: #84cefc;
    }

    .other-btn {}

    .adver {
        padding-left: 30px;
        padding-bottom: 30px;
        padding-right: 30px;
    }

    .adver-down {
        width: 500px;
        height: 35px;
    }

    .download-i {
        margin-right: 3px
    }

    .ios-modal-body-hr {
        padding-left: 50px;
        padding-right: 50px;
    }

    .text-justify {
        text-align: justify;
    }

    .ios-table {
        height: 186px;
        border-color: black;
        margin-left: auto;
        margin-right: auto;
    }

    .ios-table-title-tr {
        height: 43.8438px;
    }

    .ios-table-title-th1 {
        width: 181px;
        height: 43.8438px;
        text-align: center;
    }

    .ios-table-title-th2 {
        width: 396px;
        height: 43.8438px;
        text-align: center;
    }

    .ios-table-tr {
        height: 28px;
    }

    .ios-table-td-left {
        width: 181px;
        text-align: center;
        height: 28px;
    }

    .ios-table-td-right {
        width: 396px;
        text-align: center;
        height: 28px;
    }

    .mobile {
        display: none;
    }

    .web {
        display: block;
    }

    .ads-reminder {
        margin-bottom: 10px;
        color: white;
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        .web {
            display: none;
        }

        .mobile {
            display: block;
        }

        .panel-wrapper * {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        .panel-wrapper {
            position: fixed;
            left: 0;
            bottom: 0;
            overflow: hidden;
            width: 100%;
            font-family: sans-serif;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        .panel-controller {
            position: relative;
            overflow: hidden;
            width: 100%;
        }

        .tab-controller {
            float: right;
            margin-right: 50px;
            padding: 12px 12px 7px;
            background-color: #333;
            -webkit-border-radius: 15px 15px 0 0;
            -moz-border-radius: 15px 15px 0 0;
            border-radius: 15px 15px 0 0;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        .tab-controller * {
            display: block;
            font-family: sans-serif;
            font-size: 16px;
            font-weight: bold;
            color: white;
            cursor: pointer;
        }

        .tab-controller .show {
            display: none;
        }

        .panel-content {
            overflow: hidden;
            width: 100%;
            background-color: #333;
        }

        .panel-content .content {
            overflow: hidden;
            margin: 0 auto;
            max-width: 900px;
            width: 98%;
        }

        .btn-image-mobile {
            width: 100%
        }

        .preview-margin-mobile {
            margin-top: 20px;
        }

        .fab {
            width: 55px;
            height: 55px;
            background-color: #4286f4;
            border-radius: 50%;
            box-shadow: 0 6px 10px 0 #666;
            transition: all 0.1s ease-in-out;
            font-size: 25px;
            color: white;
            text-align: center;
            position: fixed;
            right: 30px;
            bottom: 420px;
        }

        .download-icon {
            text-align: center;
            margin-top: 15px;
        }

        .fab:active {
            box-shadow: 0 6px 14px 0 #666;
            transform: scale(1.25);
        }

        .mobile-adv {
            height: 50px !important;
            width: 100%;
            margin-bottom: 55px;
        }
    }












}