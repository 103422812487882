#app-assistance-page {

    .form-container {
        //background: #fff;
        padding: 0px;
        border-radius: 0px;
        //border: 1px solid $color-border-200;
        //box-shadow: 0px 1px 4px #00000014;
        //max-width: 500px;
        margin: 5px auto;
    }

    .progress-bar-request {
        display: flex;
        align-items: center;
        padding: 10px;
        margin: 15px 40px;
        margin-bottom: 32px;
        border-radius: 36px;
        background: white;
        box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
    }

    .step {
        width: 30px;
        height: 30px;
        line-height: 26px;
        border: 1px solid $brand-primary;
        border-radius: 50%;
        text-align: center;
        color: $brand-primary;
        font-weight: bold;
    }

    .step.active {
        @include button-variant-light($component-active-bg, $btn-primary-bg, $btn-primary-border);
    }

    .progress-bar-request hr {
        flex-grow: 1;
        border: none;
        background-color: $brand-primary;
        height: 1px;
        margin: 0 0px;
    }

    .form-section {
        display: none;
    }

    .form-section.active {
        display: block;
    }

    .form-group {
        margin-bottom: 15px;
    }

    label {
        display: block;
        margin-bottom: 5px;
    }

    input[type="tel"],
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
    }

    .submit-button {
        width: 100%;
        padding: 10px;
        @include button-variant-light($component-active-bg, $btn-primary-bg, $btn-primary-border);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        //margin-top: 10px;
    }

    .submit-button:hover {
        //background: $brand-primary;
    }

    .fullscreen-map {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        right: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .header-map {
        margin-top: calc(env(safe-area-inset-top) + 25px) !important;
    }

    #footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 24px;
    }

    #use-address {
        padding: 10px 25px;
        font-size: 16px;
        @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
        border: none;
        width: 100%;
        cursor: pointer;
    }

    #use-address:hover {
        //background-color: #3079ed;
        /* Cor de fundo do botão ao passar o mouse */
    }

    .confirmation-box {
        text-align: center;
        background: white;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid $color-border-200;
        box-shadow: 0px 1px 4px #00000014;
        margin-top: 30vh;
    }

    .confirmation-box h1 {
        color: #00B894;
        margin: 20px 0;
    }

    .confirmation-box p {
        color: #333;
        margin: 10px 0;
    }

    .confirmation-box .protocol {
        color: #555;
        font-size: 0.9em;
        margin: 20px 0;
    }

    .confirmation-box button {
        @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
        border: none;
        padding: 10px 100px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1em;
    }

    .confirmation-box .checkmark-circle {
        width: 50px;
        height: 50px;
        background: #00B894;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .checkmark-circle .checkmark {
        color: white;
        font-size: 25px;
    }

    .title-header {
        font-size: 15px;
        font-weight: 600;
        color: #334257;
        line-height: initial;
    }

    .title-sub-header {
        font-size: 12px;
        font-weight: 300;
        color: #334257;
    }

    .button-back>i {
        font-size: 14px;
        font-weight: bold;
    }

    .services {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        margin: 20px;
    }

    .service-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #FFFFFF;
        text-align: center;
        cursor: pointer;
        color: #334257;
        transition: box-shadow 0.2s ease-in-out;
    }

    .service-card:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 2px solid #39A2DB;
    }

    .service-card.selected {
        border: 2px solid #39A2DB;
    }

    .service-card.selected:hover {
        border: 2px solid #39A2DB;
    }


    .service-icon {
        height: 50px;
        margin-bottom: 0px;
        font-size: 28px;
        line-height: 50px;
    }

    .service-title {
        font-size: 8px;
        font-weight: 400;
    }

    .service-card.not-selected {
        opacity: 0.6;
    }

    #assistance-form {
        background: white;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
    }

    .hr-style {
        border: none;
        height: 4px;
        background-color: #00000014;
        margin-top: -10px;
        margin-bottom: 20px;
        width: 20%;
        border-radius: 50px;
    }

    .color-body {
        color: #334257;
    }

    .icon-size {
        line-height: 40px;
    }

    .close {
        font-size: 25px !important;
        margin-top: -5px !important;
        height: 38px !important;
        width: 38px !important;
        text-align: center !important;
        border-radius: 50px !important;
        -webkit-border-radius: 50px !important;
        -moz-border-radius: 50px !important;
        -khtml-border-radius: 50px !important;
        line-height: 23px;
        font-weight: 300 !important;
        border: 1px solid #cccccc !important;
        color: #a19d9d !important;
        @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
        opacity: 1.0;
    }




    .search-box {
        position: absolute;
        top: 25px;
        left: 0px;
        width: 100%;
        padding: 0px 19px;
        z-index: 600;
    }


    .input-box {
        padding: 10px 18px !important;
        font-size: 12px !important;
        font-weight: 300;
        border: none !important;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
        border-radius: 20px !important;
        background: white;
    }

    .input-with-icon {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 20px !important;
        ;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1) !important;
        ;
        margin-bottom: 10px;
        border: 1px solid #EEEEEE;
    }

    .input-with-icon i {
        margin: 0 10px;
        color: #555;
        font-size: 13px;
    }

    .input-with-icon .input-address {
        flex: 1;
        padding: 10px 2px !important;
        font-size: 12px;
        font-weight: 400;
        border: none !important;
        border-radius: 20px !important;
        background: white;
        height: 40px;
        outline: none;
        max-width: 86%;
    }

    .input-with-icon .fa:hover {
        cursor: pointer;
    }


    select.select-assistance {
        width: 100%;
        padding: 16px 12px;
        border-radius: 4px;
        border: 1px solid #D9D9D9;
        cursor: pointer;
        background: #fff;
        transition: all 0.3s ease;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-size: 15px;
    }


    select.select-assistance:hover,
    select.select-assistance:focus {
        border: 1px solid #D9D9D9;
        outline: none;
    }

    #checklist-container {
        padding: 2px;
        text-transform: lowercase;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .custom-radio-group {
        display: ruby-text;
    }

    .custom-radio {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .custom-radio input[type="radio"] {
        display: none;
    }

    .custom-radio label {
        margin: 0;
        padding-left: 26px;
        position: relative;
        cursor: pointer;
    }

    .custom-radio label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        border: 1px solid #ccc;
        border-radius: 50px;
        background-color: #fff;
        transition: all 0.2s;
    }

    .custom-radio label::after {
        content: '\f00c';
        font-family: 'FontAwesome';
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%) scale(0);
        font-size: 12px;
        color: #fff;
        transition: transform 0.2s;
    }

    .custom-radio input[type="radio"]:checked+label::before {
        border-color: #00B894;
        background-color: #00B894;
    }

    .custom-radio input[type="radio"]:checked+label::after {
        transform: translateY(-50%) scale(1);
    }

    .question-number {
        font-weight: 600;
        margin-right: 6px;
        border-radius: 50px;
        color: #144682;
        background: rgba(20, 70, 130, 0.2);
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        text-align: center;
        display: inline-block;
    }

    fieldset {
        margin-bottom: 20px;
        padding: 10px;
    }


    .legend-with-icon {
        position: relative;
        padding-left: 25px;
    }


    .legend-with-icon i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.2em;
    }


    .origin i {
        color: white;
        background-color: green;
        padding: 8px;
        border-radius: 50%;
    }


    .destination i {
        color: white;
        background-color: red;
        padding: 8px;
        border-radius: 50%;
    }


    .route-line {
        height: 2px;
        background-color: black;
        margin: 10px 0;
    }

    #camera-feed {
        position: relative;
        width: 100%;
        max-width: 800px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }

    #video {
        background-color: #000;
        width: 100%;
        height: 60vh;
        max-height: 60vh;
        object-fit: cover;
    }

    #photos-container {
        position: relative;
        display: flex;
        align-items: center;
    }

    .navigation-button {
        display: none;
        background-color: #007bff;
        border: none;
        color: white;
        padding: 10px;
        cursor: pointer;
        font-size: 20px;
        z-index: 10;
    }

    #nav-left {
        position: absolute;
        left: 0;
    }

    #nav-right {
        position: absolute;
        right: 0;
    }

    #photos {
        display: flex;
        align-items: center;
        height: 100%;
        overflow: hidden;
    }

    #photos img {
        width: 90px;
        height: 90px;
        min-height: 90px;
        margin: 5px;
        border-radius: 5px;
        object-fit: cover;
        object-position: center;
    }


    .capture-button,
    .remove-button-image {
        font-size: 24px;
        padding: 15px;
    }

    .remove-button-image {
        background-color: #ff4d4d;
    }

    button:disabled {
        background-color: #ccc !important;
        cursor: not-allowed !important;
        color: #777777 !important;
    }

    .modal-content {
        width: 100%;
        height: auto;
    }

    #cropped-image {
        max-width: 100%;
        border-radius: 5px;
    }

    .photo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: fit-content;
    }

    .remove-button {
        align-self: center;
        position: absolute;
        translate: 2px -5px;
        padding: 11px 12px;
        border-radius: 50px;
        text-align: center;
        background: #D63031;
        font-size: 11px;
        border: none;
    }

    input:focus,
    select:focus {
        border: 2px solid #4A90E2;
        outline: none;
    }

    .is-invalid {
        border: 2px solid #D63031 !important;
    }

    .focused {
        border: 2px solid #D63031;
        outline: none;
        border-radius: 4px;
        padding: 10px;
    }

    .custom-icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-size: cover;
        box-shadow: 0 0 0 3px white, 0 0 0 5px #2A93EE;
        animation: pulseBorder 1s infinite;
        -webkit-animation: pulseBorder 1s infinite;
    }

    @keyframes pulseBorder {
        0% {
            box-shadow: 0 0 0 3px white, 0 0 0 5px #2A93EE;
        }

        50% {
            box-shadow: 0 0 0 4px white, 0 0 0 6px #2A93EE;
        }

        100% {
            box-shadow: 0 0 0 3px white, 0 0 0 5px #2A93EE;
        }
    }

    @-webkit-keyframes pulseBorder {
        0% {
            box-shadow: 0 0 0 3px white, 0 0 0 5px #2A93EE;
        }

        50% {
            box-shadow: 0 0 0 4px white, 0 0 0 6px #2A93EE;
        }

        100% {
            box-shadow: 0 0 0 3px white, 0 0 0 5px #2A93EE;
        }
    }


    .bottom-sheet-wrapper {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        transform: translateY(100%);
        z-index: 1000;
    }

    .bottom-sheet-wrapper.show-modal {
        transform: translateY(50%);
        box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
    }

    .bottom-sheet {
        background-color: white;
        border-radius: 15px 15px 0 0;
        padding: 20px;
        height: 40em;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }

    .bottom-sheet-wrapper-address {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        transform: translateY(100%);
        z-index: 1002;
    }

    .bottom-sheet-wrapper-address.show-modal {
        transform: translateY(50%);
        box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
    }

    .bottom-sheet-address {
        background-color: white;
        border-radius: 15px 15px 0 0;
        padding: 20px;
        height: 60em;
        max-height: 100vh;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }


    .bottom-sheet-wrapper-address-origem {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        transform: translateY(100%);
        z-index: 1002;
    }

    .bottom-sheet-wrapper-address-origem.show-modal {
        transform: translateY(50%);
        box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
    }

    .bottom-sheet-address-origem {
        background-color: white;
        border-radius: 15px 15px 0 0;
        padding: 20px;
        max-height: 100vh;
        height: 50em;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }



    .line {
        width: 2px;
        background-color: #77777787;
        height: 12px;
        margin: 0px 6px;
    }

    #results-container {
        list-style: none;
        padding: 0;
        margin: 10px 0;
        max-height: 300px;
        overflow-y: auto;
        border-radius: 5px;
    }

    #results-container li {
        padding: 10px 15px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
    }

    #results-container li i {
        margin-right: 8px;
        background: #e67e2226;
        border-radius: 50px;
        height: 20px;
        width: 20px;
        padding: 5px 6px;
        color: #e67e22;
    }

    #results-container li span {
        font-size: 11px;
        font-weight: 300;
    }

    #results-container li:hover {
        background-color: #e9e9e9;
    }

    #results-container li:last-child {
        border-bottom: none;
    }

    .results-container-empty {
        border: none;
    }

    .icon-fallback {
        font-size: 50px;
        color: #D9D9D9;
    }

    .text-fallback {
        font-size: 12px;
        color: #334257;
        font-weight: 300;
    }

    .checkbox-inline input[type="checkbox"]:checked+label::before {
        color: #fff;
        background-color: #00b894;
        border-radius: 25px !important;
    }

    .checkbox-inline input[type="checkbox"]:checked+label::after {
        background-color: #00b894;
        border-color: #00b894;
        border-radius: 25px !important;
    }

    .rounded-2,
    .checkbox label::before,
    .checkbox label::after,
    .checkbox-inline label::before,
    .table .checkbox label::before,
    .group .checkbox label::before,
    .checkbox-inline label::after,
    .table .checkbox label::after,
    .group .checkbox label::after,
    .leaflet-control-layers label div span::before,
    .leaflet-control-layers label div span::after,
    .bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text::before,
    .bootstrap-select.btn-group.show-tick .leaflet-control-layers .leaflet-control-layers-list li a span.text::before,
    .leaflet-control-layers .bootstrap-select.btn-group.show-tick .leaflet-control-layers-list li a span.text::before,
    .bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text::after,
    .bootstrap-select.btn-group.show-tick .leaflet-control-layers .leaflet-control-layers-list li a span.text::after,
    .leaflet-control-layers .bootstrap-select.btn-group.show-tick .leaflet-control-layers-list li a span.text::after {
        border-radius: 25px !important;
    }

    .starred-notfound {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 400px;
    }

    .starred-notfound i {
        color: #D9D9D9;
        font-size: 100px;
        margin: 0 auto;
    }

    .starred-notfound-title {
        color: #334257;
        font-size: 16pt;
        font-weight: bold;
        margin: 16px 0;
    }

    .starred-notfound-info {
        color: #666;
        font-weight: 300;
        text-align: center;
    }


    .action-buttons {
        height: 40px !important;
        width: 40px !important;
        line-height: 19px;
        font-size: 13px;
        -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
        box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
        border: var(--bs-border-width) var(--bs-border-style) #d9d9d9 !important;
    }

    .card {
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        flex: 1;
        align-self: stretch;
        border-radius: 10px !important;
    }

    .bg-success-light {
        background: rgba(92, 184, 92, 0.2) !important;
        color: #5cb85c !important;
    }


    .carousel {
        display: flex;
        align-items: center
    }

    .carousel-item {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background-size: cover;
        background-position: center;
        margin-right: 6px;
        position: relative;
        border: 1px solid #bbb;
        box-shadow: 0 0 4px rgba(0, 0, 0, .1);
        cursor: pointer
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }






}



.leaflet-routing-container,
.leaflet-bar,
.leaflet-routing-collapsible,
.leaflet-control,
.leaflet-routing-container-hide {
    display: none !important;
}

.assitance-map-overflow {
    overflow: hidden;
}

.bg-confirm {
    font-size: 25px !important;
    margin-top: -5px !important;
    height: 38px !important;
    width: 38px !important;
    text-align: center !important;
    background: #00B894 !important;
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -khtml-border-radius: 50px !important;
    line-height: 23px;
    font-weight: 300 !important;
    border: 1px solid #00B894 !important;
    color: white !important;
    @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
    opacity: 1.0;
    padding: 0px;
}