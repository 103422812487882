.leaflet-contextmenu {
    display: none;
    background-color: $body-bg !important;
    @include box-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 4px 0;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.leaflet-contextmenu a.leaflet-contextmenu-item {
    display: block;
    color: #666;
    //font-size: 10px;
    line-height: 20px;
    text-decoration: none;
    //padding: 0 12px;
    padding: 5px 10px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    cursor: default;
    outline: 0
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled {
    opacity: .5
}

.leaflet-contextmenu a.leaflet-contextmenu-item.over {
    background-color: #f4f4f4;
    border-radius: 10px;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled.over {
    background-color: inherit;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent
}

.leaflet-contextmenu-icon {
    margin: 2px 8px 0 0;
    width: 16px;
    height: 16px;
    float: left;
    border: 0
}

.leaflet-contextmenu-separator {
    border-bottom: 1px solid #ccc;
    margin: 5px 0
}