$scrollbar-size: 0px !default;
$bg-yellow: rgb(255, 234, 167) !default;
$color-text-800: #334257;
$skeletron-color: #dddbdd !default;


$btn_bg_reports: #e3e4e5;
$btn_bg_alerts: #fdeaeb;
$btn_bg_share_location: #e9f9ee;
$btn_bg_active_device: #e8fafd;


$btn_color_reports: #6610f1;
$btn_color_alerts: #ea5355;
$btn_color_share_location: #29c76f;
$btn_color_active_device: #04cfe7;
$color-sensor-modal-new: #777777;