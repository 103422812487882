@import "bootstrap-checkbox-mixins";

@include checkbox('.checkbox', 'label');
@include checkbox('.checkbox-inline', 'label');

@include checkbox-variant('.checkbox-inline', $brand-primary);
@include checkbox-variant('.checkbox', $brand-primary);
@include checkbox-variant('.checkbox-primary', $brand-primary);
@include checkbox-variant('.checkbox-danger',  $brand-danger);
@include checkbox-variant('.checkbox-info',    $brand-info);
@include checkbox-variant('.checkbox-warning', $brand-warning);
@include checkbox-variant('.checkbox-success', $brand-success);

.checkbox,
.checkbox-inline {
  &.checkbox-lg {
    label{
      &::before{
        width: ($checkbox-size + 10 - 2);
        height: ($checkbox-size + 10 - 2);
        font-size: ($checkbox-font-size + 10);
      }
      &::after{
        width: ($checkbox-size + 10);
        height: ($checkbox-size + 10);
      }

    }
  }
}

.radio,
.radio-inline {
  line-height: 1;
  position: relative;
  //display: block;
  //margin: 0 0 5px 0;
  padding-left: 0;

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 0px;

    &::before{
      content: ' ';
      position: relative;
      z-index: 1;
      left: 0;
      width: ($checkbox-size - 6);
      height: ($checkbox-size - 6);
      display: inline-block;
      vertical-align: middle;
      color: transparent;
      background-color: $checkbox-background-color;
      border-radius: 50%;

      margin: 3px;
      margin-right: 5px;

      //@include scale(0, 0);
      @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
    }

    &::after{
      display: inline-block;
      position: absolute;
      content: ' ';
      width: $checkbox-size;
      height: $checkbox-size;
      left: 0;
      top: 0;

      border-radius: 50%;
      border: 1px solid $checkbox-border-color;
      background-color: $checkbox-background-color;

      @include transition(border 0.15s ease-in-out);
    }
  }

  input[type="radio"]{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    opacity: 0;

    &:focus + label::before{
      //@include tab-focus();
      outline: 0;
    }

    &:checked + label::before{
      //@include scale(1, 1);
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        cursor: not-allowed;
      }
    }
  }

  &.radio-inline{
    margin-top: 0;
  }
}

@include radio-variant('.radio-inline', $brand-primary);
@include radio-variant('.radio', $brand-primary);
@include radio-variant('.radio-primary', $brand-primary);
@include radio-variant('.radio-danger',  $brand-danger);
@include radio-variant('.radio-info',    $brand-info);
@include radio-variant('.radio-warning', $brand-warning);
@include radio-variant('.radio-success', $brand-success);


