#device-modal {
   
    .nav-default {
        background-color: transparent !important;
    }

    .nav-default > li > a {
        background-color: transparent !important;
        @extend .fw-normal;
    }
   
    .nav-tabs {
        overflow-x: auto;
        overflow-y: hidden;
        display: -webkit-box;
        display: -moz-box;
            &::-webkit-scrollbar {
              width: 1px;
              height: 1px;
            }
          
            &::-webkit-scrollbar-track {
              background: transparent !important;
              border: 1px solid transparent !important;
            }
          
            &::-webkit-scrollbar-thumb {
              background: transparent !important;
              border: 1px solid transparent !important;
            }
        
    }
    
    .nav-tabs>li {
        float: none;
    }

    .user-label{
        background: #e7e7e7;
        padding: 1px 7px;
        border-radius: 5px;
        margin-right: 2px;
        color: #5e5e5e;
        display: flex;

    }

    .user-label .remover{
        margin-left: 5px;
        padding: 1px;
        margin-top: 1px;
        border-radius: 50%;
        display: block;
        width: 17px;
        height: 17px;
        font-size: 10px;
        text-align: center;
    }

    .user-label .remover:hover{
        background: silver;

    }

    #user-list{
     
     @extend .d-flex;
     @extend .align-content-start;
     @extend .flex-wrap;
     @extend .gap-2;

    }

    .css-13cymwt-control {
        -webkit-box-align: center;
        align-items: center;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        position: relative;
        transition: all 100ms ease 0s;
        background-color: rgb(255, 255, 255);
        border-color: rgb(204, 204, 204);
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        outline: 0px !important;
        @extend .rounded-4;
    }
    .css-1dyz3mf {
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        flex: 1 1 0%;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        padding: 5px 8px;
        box-sizing: border-box;
    }

    .btn-status-disable {
        color: #a9a0a0bd ;
        background-color: #eee;
        @extend .fw-semibold;
       
    }

    .btn-status-disable:active, .btn-status-disable.active, .open > .btn-status-disable.dropdown-toggle {
        color:$component-active-bg  !important;
        @include background-opacity($component-active-bg, 0.2);
        @extend .fw-semibold;
    }
    .nav-default>li.active:after {
        @include background-opacity($component-active-bg, 0.2);
        @extend .fw-semibold;
        height: 38px;
        @extend .rounded-3;
    }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
        color:$component-active-bg  !important;
        @extend .fw-semibold;
    }

    .nav-default > li > a {
        @extend .fw-semibold;
    }

    .mt-1-custom {
        margin-top: 0.2rem !important;
    }

    
    
}
  
  