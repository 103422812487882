#menu_btn {
    display: none;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 20px;
    top: 10px;
    background: white;
    border-radius: 50%;
    padding: 10px;
    z-index: 999;
    font-size: 16px;
    text-align: center;
}

.avatar-user-mobile {

    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: $color-bg-000;
    color: silver;
    text-align: center;
    font-size: 20px;
}

.card-account-info {

    background: $color-bg-000;
    box-shadow: rgba(0, 0, 0, 0.08) 0px -2px 17px
}

select.flatpickr-monthDropdown-months {
    /* for Firefox */
    -moz-appearance: none !important;
    /* for Chrome */
    -webkit-appearance: none !important;
}

/* For IE10 */
select.flatpickr-monthDropdown-months::-ms-expand {
    display: none !important;
}

#desk_map {
    #map_btns {
        position: absolute;
        left: 490px;
        top: 22px;
        z-index: 500;
        width: 40px;
    }


    #map_change {
        z-index: 600;
        position: absolute;
        bottom: 40px;
        left: 497px;
        width: calc(100vw - 520px);
        background: $body-bg;
        max-height: 0%;
        overflow: overlay;
        transition: max-height 0.3s;
        border-radius: 10px;
    }

    #mapLayersBtn {
        position: absolute;
        bottom: 50px;
        right: 50px;
        z-index: 999;
        transition: bottom 0.3s;
    }

    .open {
        #map_change {
            max-height: 100vh !important;
        }

        #mapLayersBtn {
            bottom: 225px;
        }
    }
}


#mobile_map {
    #map_btns_right {
        position: absolute;
        top: 22px;
        left: 10px;
        z-index: 500;
    }


    #map_btns {
        position: absolute;
        right: 10px;
        top: 22px;
        z-index: 500;
    }




    #mobile_box {
        .car-image-area {
            width: 100px;

            .round-img {
                width: 100px;
                height: 100px;

            }

            .spedometer {
                font-size: 9px;
            }
        }

        .car--info {
            h5 {
                font-size: 15px;
                line-height: 1.2;
                margin: 0 0 5px;

                span {
                    font-size: 10px;
                }
            }


            .conect-sd {
                font-size: 10px;

                b {
                    font-size: 11px;
                }
            }

            .locatisn {
                margin-top: 5px;
            }


        }
    }
}


#map_settings {
    .active .img {

        border: $component-active-bg 3px solid !important;
        opacity: 0.5;
        @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
    }
}


.hide-on-desk {
    display: none;
}

#right_bar {
    position: fixed;
    right: 0px;
    width: 400px;
    height: 100vh;
    top: 0px;
    background: $body-bg;
    z-index: 1010;
    max-width: 0%;
    transition: max-width 0.5s;
    overflow: hidden;

    &.open {
        max-width: 100vw !important;
        -webkit-box-shadow: 0 2px 20px 12px rgba(0, 0, 0, .14);
        box-shadow: 0 2px 20px 12px rgba(0, 0, 0, .14);
    }
}

#right_events_management {
    position: absolute;
    right: 0px;
    width: 400px;
    height: 100vh;
    top: 0px;
    background: #f4f5f7;
    z-index: 1000;
    max-width: 0%;
    transition: max-width 0.5s;
    overflow: hidden;

    &.open {
        max-width: 100vw !important;
        -webkit-box-shadow: 0 2px 20px 12px rgba(0, 0, 0, .14);
        box-shadow: 0 2px 20px 12px rgba(0, 0, 0, .14);
    }
}

@media only screen and (max-width: 768px) {

    .manage_cards {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 100px;

    }

    .manage-input {
        flex: 1;
    }

    .card-item-manage {
        width: 150px !important;
        height: 100px !important;
    }

    .right_page {
        background: $body-bg;
        width: 100vw;
        height: 100vh;
        position: fixed;
        right: -100vw;
        top: 0px;
        z-index: 1010;
        transition: right 0.3s ease-in-out;



        &.show {
            right: 0px !important;
        }
    }


    #right_bar {
        width: 100vw !important;
    }

    #right_events_management {
        width: 100vw !important;
    }

    .l-sidebar {
        width: 0px;
        overflow: hidden;
    }

    #sidebar {
        left: 0px;
        padding-bottom: 100px;
        /* Disable bounces for a specific element with the class "scrollable" */
        overscroll-behavior: none;
    }

    .overlay-sidebar-devices {
        left: 0 !important;
    }

    .overlay-sidebar-devices-content {
        left: 0 !important;
    }

    #menu_btn {
        display: block;
    }

    .search-is-overlay-open #menu_btn {
        display: none !important;
    }

    .sidebar-is-expanded #menu_btn {
        display: none !important;
    }


    #main-change {
        margin-left: 0px !important;
    }

    .sidebar-is-expanded #main-change {
        margin-left: 0px !important;
    }

    #main-change h3 {
        text-align: center !important;
        margin-top: -15px;
    }

    .title-center-device-mobile {
        text-align: center !important;
    }

    .ms-0-custom-mobile {
        margin-left: 0 !important;
    }


    .modal-sm-10 {
        width: 83.33%;
    }

    /*Modal center in mobile*/
    .modal-center {
        text-align: center;
        padding: 0 !important;
    }

    .modal-center:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }

    .modal-dialog-center {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
    }

    .ios-status-bar {
        //padding-top: calc(10% + env(safe-area-inset-top));
        //padding-left: env(safe-area-inset-left);
        //padding-right: env(safe-area-inset-right);

    }




    #mobile_box {
        display: block;
        position: fixed;
        z-index: 1005;
        bottom: 105px;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);

        div[id*=supense] {
            display: none !important;
        }
    }

    #menu_mobile {
        position: fixed;
        left: 50%;
        bottom: 20px;
        background: $color-bg-000;
        width: calc(90% - 1rem);
        transform: translateX(-50%);
        height: 69px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px;
        z-index: 1020;
        border-radius: 10px;

        ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            padding: 0px 20px;

            li {
                text-align: center;
                color: $text-color;

                div {
                    i {
                        font-size: 16px;
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }

                    .text {
                        font-size: 10px;
                        font-weight: 300;
                        text-transform: lowercase;
                    }
                }


                &.is-active {
                    color: $link-color !important;
                }
            }

            &.isopen-notifications {
                li {
                    text-align: center;
                    color: $text-color !important;

                    div {
                        i {
                            font-size: 16px;
                            margin-top: 15px;
                            margin-bottom: 5px;
                        }

                        .text {
                            font-size: 10px;
                            font-weight: 300;
                            text-transform: lowercase;
                        }
                    }


                    &.is-notification {
                        color: $link-color !important;
                    }
                }
            }


            &.isopen-support {
                li {
                    text-align: center;
                    color: $text-color !important;

                    div {
                        i {
                            font-size: 16px;
                            margin-top: 15px;
                            margin-bottom: 5px;
                        }

                        .text {
                            font-size: 10px;
                            font-weight: 300;
                            text-transform: lowercase;
                        }
                    }


                    &.is-support {
                        color: rgba(57, 162, 219, 1) !important;
                    }
                }
            }

            &.isopen-myaccount {
                li {
                    text-align: center;
                    color: $text-color !important;

                    div {
                        i {
                            font-size: 16px;
                            margin-top: 15px;
                            margin-bottom: 5px;
                        }

                        .text {
                            font-size: 10px;
                            font-weight: 300;
                            text-transform: lowercase;
                        }
                    }


                    &.is-myaccount {
                        color: rgba(57, 162, 219, 1) !important;

                        #img {
                            border: rgba(57, 162, 219, 1) 1px solid !important;
                        }
                    }
                }
            }
        }
    }


    .hide-on-desk {
        display: block !important;
    }

    .hide-on-mobile {
        display: none !important;
    }

    .sidebar-hide-on-mobile {
        display: none !important;
    }


    #bottombar {
        display: none !important;
    }


    #containerApi {
        margin-bottom: 100px;
        margin-top: calc(env(safe-area-inset-top) + 25px) !important;
    }

    .modal-content {

        //transform: translateY(-50%) !important;
        //position: absolute !important;
        //top: 50vh !important;
        //width: 100% !important;
    }


    #mobile_map {
        #map_change {
            z-index: 1020;
            position: absolute;
            bottom: 0px;
            left: 50%;
            width: 100vw;
            background: $body-bg;
            transform: translateX(-50%);
            max-height: 0%;
            overflow: hidden;
            transition: max-height 0.3s;





        }

        #overlay {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100vw;
            height: 100vh;
            background: #334257CC !important;
            z-index: 1019;
            display: none;
        }

        &.mapopen {


            #overlay {
                display: block;
            }

            #map_change {
                max-height: 100vh !important;
            }
        }


    }

    .perimeter-edit {

        #mobile_map,
        #menu_mobile,
        .sidebar_devices {
            display: none !important;
        }

        #menu_perimeter {
            display: block !important;
        }
    }
}