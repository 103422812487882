@mixin background-opacity-checkbox($color, $opacity: 0.3) {
	background: $color !important; /* The Fallback */
	background: rgba($color, $opacity) !important;
}


.s-switch input[type='checkbox']{display:none}

.s-switch {
	width: 47px;
	height: 26px;
	background: #E5E5E5;
	z-index: 0;
	margin: 0;
	padding: 0;
	appearance: none;
	border: none;
	cursor: pointer;
	position: relative;
	border-radius:16px; 
	-moz-border-radius:16px; 
	-webkit-border-radius:16px;
}

.s-switch:before {
	content: ' ';
	background: $sidebar-bg!important;
	position: absolute;
	left: 1px;
	top: 1px;
	width: 45px;
	height: 24px;
	background: #FFFFFF;
	z-index: 1;
	border-radius:16px; 
	-moz-border-radius:16px; 
	-webkit-border-radius:16px;
}

.s-switch:after {
	content: ' ';
	height: 23px;
	width: 23px;
	border-radius: 28px;
	z-index: 2;
	background: #FFFFFF;
	position: absolute;
	-webkit-transition-duration: 300ms;
	transition-duration: 300ms;
	top: 1.5px;
	left: 2px;
	-webkit-box-shadow: 0 2px 5px #999999;
	box-shadow: 0 2px 5px #999999;
}

.s-switch-on, .s-switch-on:before{
    background: $component-active-bg !important;
	//@include background-opacity-checkbox($component-active-bg, 0.);
}
.s-switch-on:after{
	left: 22px !important;
}
.s-switch-readonly, .s-switch-disabled{
	opacity: 0.8;
}