.my-div-icon-number .outer {
    background: white;
    border: rgb(24, 24, 24) 5px solid;
    color: rgb(24, 24, 24);
    /* padding: 5px; */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


#device_list {
    background: $sidebar-bg;
    padding-bottom: 20px;

    @media only screen and (max-width: 768px) {
        margin-bottom: 100px;
    }
}

#street-view-iframe {
    min-width: 100px;
    min-height: 200px;
}

a:hover {
    cursor: pointer;
}

.ph-row div {

    border-radius: 50px !important;
}

.ph-item {
    border: none !important;
    margin-bottom: 0px !important;
}

.card-skeleton-view {
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex: 1;
    align-self: stretch;
    border-radius: 10px !important;
    overflow: hidden;
    position: relative;
}


.mobile-px {
    @media only screen and (max-width: 768px) {

        margin-left: 0rem !important;
        margin-right: 0rem !important;

    }

    @extend .ms-3;
    @extend .me-3;
}


@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@keyframes load-out {
    100% {
        width: 0%;
    }
}

@-webkit-keyframes rotor {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotor {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes rotor {
    from {
        -o-transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
    }
}

@keyframes rotor {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.sidebar_devices {
    overflow-y: auto;
}

.sidebar_account,
.right_page {
    &.loading {
        &:after {

            width: calc(100%) !important;
        }
    }
}

.sidebar_devices,
.sidebar_account,
.right_page,
#mobile_box,
#mobile_map {
    &.loading {
        &:after {
            position: absolute;
            left: 0px;
            top: 0px;
            width: calc(100% + 10px);
            height: 100vh;
            background: rgba(1, 32, 68, 0.4);
            content: " ";
            z-index: 999999998;
        }

        &:before {
            position: absolute;
            left: 50%;
            top: 50vh;
            width: 80px;
            height: 80px;
            content: " ";
            z-index: 999999999;
            background: url('/assets/images/loading-branco.svg') center no-repeat;
            transform: translate(-50%, -50%);
        }
    }


    @extend .scrollbar;

    .action-buttons {
        height: 40px !important;
        width: 40px !important;
        line-height: 19px;
        font-size: 13px;
        @extend .border;
        @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));

        &.loader-wrap {
            width: 44px !important;
            height: 44px !important;
            display: inline-block !important;
            position: relative !important;
            box-shadow: none !important;
        }

        .clicker {
            /* Inner circle */
            width: 40px;
            height: 40px;
            /* 20px smaller b/c of margin below */
            margin: 1px;
            background-color: #fff;
            border-radius: 60px;

            /* Overlays this circle on the .circle */
            z-index: 2;
            position: absolute;
            left: 0px;
            top: 0px;

            padding: calc(1rem - 1px);
            text-align: center;
            @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
        }

        &.is-waiting {

            @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));

            .clicker {
                box-shadow: none !important;
            }

            .outer-circle {
                /** outer circle **/
                width: 44px;
                height: 44px;
                border-radius: 50%;

                /** image = wedge **/
                background-image: linear-gradient(45deg,
                        transparent 0%,
                        transparent 20%,
                        $component-active-bg 30%,
                        $component-active-bg 70%,
                        transparent 80%,
                        transparent 100%);

                /*** outer circle position: under */
                z-index: 1;
                position: absolute;
                left: 0px;
                top: 0px;


                -webkit-animation: rotor 1.5s linear 0s infinite normal;
                -mox-animation: rotor 1.5s linear 0s infinite normal;
                -o-animation: rotor 1.5s linear 0s infinite normal;
                animation: rotor 1.5s linear 0s infinite normal;
            }
        }
    }



    .custom-loader.show {
        background: #e3c975cb;
        width: 100%;
        height: 3px;
        position: relative;

        &:after {
            content: " ";
            position: absolute;
            left: 0px;
            top: 0px;
            height: 3px;
            width: 100%;
            background: #FFEAA7;
            animation: load-out 30s ease-in;
        }
    }


    .is-waiting {
        cursor: wait !important;
    }

    .is-waiting i {
        color: silver !important;
    }

    .card {
        border-radius: 5px;
        padding: 3px;
        padding-left: 40px;
        padding-right: 40px;
        flex: 1;
        align-self: stretch;

    }

    .card-street-view {
        padding: 0px;
        padding-left: 0px;
        padding-right: 0px;
        flex: 1;
        align-self: stretch;
        border-radius: 10px !important;
        overflow: hidden;
        position: relative;
    }

    .device_sensor {
        border: #dbdbdb 1px solid;
        border-radius: 5px;
        padding: 8px;
        font-size: 14px;
        @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
    }

    .device_sensor i {
        color: #dbdbdb;
    }

    .flex-1 {
        flex: 1;
    }

    .bg-car {
        background-color: $sidebar-bg;
        //background-color: transparent !important;
    }

    .bg-car::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background-color: $sidebar-bg;
        z-index: -1;
    }

    .device-info {
        position: relative;
        margin-top: 10px;
        width: 70px;
        height: 81px;
        right: 5px;

        >.device-image {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            @include box-shadow(0 0px 5px rgba(0, 0, 0, 0.15));
            border: white 1px solid;
        }

        >.device-avatar-user-mini {
            background: #f8f8f8;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            position: absolute;
            left: 0px;
            top: 0px;
            border: white 1px solid;

            >i {
                margin: 6px;
                font-size: 10px;
            }
        }

        >.device-user-status {
            background: #00B894;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 0px;
            top: 15px;
            border: white 1px solid;
        }

        >.device-status-bar {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            bottom: -12px;
            background: $color-bg-000;
            z-index: 999;
            padding: 1px 5px;
            //border: #b4bfc3 1px solid;
            border-radius: 5px;
            text-align: center;
            font-size: 1.0rem;
            //@include box-shadow(0 0px 3px rgba(0, 0, 0, 0.1));
            color: #ed1f1f;

            .skeleton-loading {
                background: #f3f3f3;
                min-height: 17px;
                min-width: 100px;
                margin-left: -5px;
                margin-right: -5px;
                border-radius: 4px;
                position: relative;
                overflow: hidden;

                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    transform: translateX(-100%);
                    background-image: linear-gradient(75deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 20%, rgba(255, 255, 255, 0.75) 60%, rgba(255, 255, 255, 0));
                    animation: shimmer 2s infinite;
                    content: '';
                }
            }
        }
    }

    .device-name {
        margin-top: 5px;
        color: #bfd0e9;
        font-size: 14pt;
        font-weight: 600;
    }

    .device-model {
        color: #bfd0e9;
        font-size: 11pt;
        font-weight: 400;
        margin-top: -3pt;
    }

    .device_sensor_custom_connect {
        margin-top: 10px;
        font-size: 10px;
        padding: 6px 4px;
        font-weight: 300;

        >i {
            margin-right: 3px;
            font-size: 10px;
        }

        >.time-connect {
            font-size: 10px;
            color: #354555;
            font-weight: 400;
        }
    }

    .device_sensor_custom_stop {
        margin-top: 10px;
        font-size: 10px;
        padding: 6px 4px;
        font-weight: 300;

        >i {
            margin-right: 3px;
            font-size: 10px;
        }

        >.time-stop {
            font-size: 10px;
            color: #00B894;
            font-weight: 400;
        }
    }

    .device_address {
        //white-space: nowrap;
        word-break: break-all;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #354555;
        font-size: 8pt;
        font-weight: 300;


        .device-address-text {
            width: 100%;
            line-height: 10px;
        }

        .icon-location {
            color: #61b4e2;
            margin-right: 5px;
            margin-top: 6px;
        }
    }

    .button-back {
        font-size: 12px;

        >.back-text {
            font-size: 13px;
        }

        >i {
            font-size: 14px;
            font-weight: bold;
        }
    }


    .device_detail {

        .device-info {
            position: relative;
            margin-top: 10px;
            width: 160px;
            height: 160px;
            margin-left: calc(100% - 145%);

            >.device-image {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                overflow: hidden;
                @include box-shadow(0 0px 2px rgba(0, 0, 0, 0.15));
                border: 1px solid $color-border-100;
            }

        }

        .device-detail-actions-left {
            .badge-action-1 {
                font-size: 8px;
                @extend .fw-semibold;
                width: 14px;
                height: 14px;
                line-height: 9px;

            }

        }

        .nav-default>li>a {
            background-color: transparent;
            border-color: transparent;
            font-weight: 300;
            font-size: 10px;

            @media only screen and (max-width: 768px) {
                font-size: 11px;

            }
        }

        .nav-default>li.active:after {
            width: 100%;
            height: 3px;


        }

        .nav>li>a {
            padding: 10px 10px;

            @media only screen and (max-width: 768px) {
                padding: 10px 15px;

            }
        }

        .nav-tabs>li.active>a,
        .nav-tabs>li.active>a:hover,
        .nav-tabs>li.active>a:focus {
            color: $component-active-bg;
        }

        .nav-default {

            background: linear-gradient(180deg, darken($color-bg-000, 0), darken($color-bg-300, 0))
        }

        .nav-tabs {
            border-bottom: 0px solid transparent;

            >li {
                display: table-cell;
                width: 1%;
                float: none;

                >a {
                    text-align: center;
                }
            }
        }

        .card {
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            flex: 1;
            align-self: stretch;
            border-radius: 10px !important;
        }

        .card-1 {
            padding: 0px;
            flex: 1;
            align-self: stretch;
            border-radius: 5px !important;
        }

        .card-street-view {
            padding: 0px;
            padding-left: 0px;
            padding-right: 0px;
            flex: 1;
            align-self: stretch;
            border-radius: 10px !important;
            overflow: hidden;
            position: relative;
        }

        .progress {
            height: 10px;
        }

        .mt-n1 {
            margin-top: -1.0rem !important;
        }

        .fs-custom-15 {
            font-size: 15px !important;
            font-weight: 400 !important;
        }

        .fs-custom-15-sub-text {
            font-size: 15px !important;
            font-weight: 300 !important;
        }

        .fs-custom-tanque {
            font-size: 14px !important;
            font-weight: 600 !important;
        }

        .fs-custom-percent {
            font-size: 20px !important;
        }

        .fs-custom-calibrar {
            font-size: 11px !important;
            font-weight: 400;
        }

        .fs-custom-apxmt {
            font-size: 10px !important;
        }

        .fs-custom-conferir {
            font-size: 9px !important;
        }

        .fs-custom-title-card-1 {
            font-size: 9px !important;
            font-weight: bold;
        }

        .fs-custom-km-card-1 {
            font-size: 13px !important;
            font-weight: bold;
        }

        .fs-custom-text-media-card-1 {
            font-size: 9px !important;
        }

        .fw-texto-500 {
            font-weight: 500;
        }

        .fw-texto-300 {
            font-weight: 300;
        }

        .fw-texto-200 {
            font-weight: 200;
        }

        .fs-custom-aprox {
            font-size: 10px !important;
            font-weight: 300;
        }

        .progress-bar {
            border-radius: 10px !important;
        }

        .progress {
            @include box-shadow(0 0px 2px rgba(0, 0, 0, 0.10));
        }

        #chartdiv1 {

            min-width: 100%;
            min-height: 70px;
            //left: -15px;
            //position: relative;

        }

        #chartdiv2 {
            //height: 70px;
            //width: calc(100% / 2 + 100px);
            min-width: 100%;
            min-height: 70px;
            //left: -15px;
            //position: relative;



        }

        #chartdiv3 {

            min-width: 100%;
            min-height: 70px;
            //left: -15px;
            //position: relative;

        }

        #chartdiv4 {
            //height: 70px;
            //width: calc(100% / 2 + 100px);
            min-width: 100%;
            min-height: 70px;
            //left: -15px;
            //position: relative;



        }

        .mobile-padding {
            @media only screen and (max-width: 768px) {

                padding-right: 3rem !important;
                padding-left: 3rem !important;

            }

        }


        .nav {
            @media only screen and (max-width: 768px) {
                //padding-right: 0.6rem !important;
                //padding-left: 0.6rem !important;
            }

        }





    }

}

.btnSync {
    background: none;
    color: rgb(219, 219, 219);
    font-size: 11px;
    width: 20px;
    height: 20px;
    padding: 0px;
    border-radius: 50%;
    border: none;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.btnSync.syncLoading i {
    color: rgb(49, 49, 49);
    animation: rotation 2s infinite linear;
}




#device-edit-container .plan {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
    padding: 20px 8px !important;
}


#device-edit-container .img {
    border-radius: 50%;
    width: 170px;
    height: 170px;
    background: $gray-base;
    border: rgb(138, 146, 159) 1px dashed;
    position: relative;
    overflow: hidden;
}

#device_detail .img {
    border-radius: 50%;
    width: 157px;
    height: 157px;
    background: $gray-base;
    position: relative;
    overflow: hidden;
}

#mobile_box .img,
#device_list .img {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background: white;
    position: relative;
    overflow: hidden;
}

#device-edit-container .img .uploader {
    z-index: 2;
    background: rgba(138, 146, 159, 0.9);
    color: white;
    width: 155px;
    height: 155px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}

#device_detail .img .uploader {
    z-index: 2;
    background: rgba(138, 146, 159, 0.9);
    color: white;
    width: 155px;
    height: 155px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}


#device-edit-container .img .icon {
    z-index: 1;
    background: white;
    color: rgb(138, 146, 159);
    width: 155px;
    height: 155px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    font-size: 40px;
}

#device_detail .img .icon {
    z-index: 1;
    background: white;
    color: rgb(138, 146, 159);
    width: 155px;
    height: 155px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    font-size: 40px;
}

#device-edit-container .img .icon i {
    margin-top: 50%;
    transform: translateY(-50%);
}

#device-edit-container .img:hover .uploader {
    display: block;
    opacity: 1;
}

#device_detail .img .icon i {
    margin-top: 50%;
    transform: translateY(-50%);
}

#device_detail .img:hover .uploader {
    display: block;
    opacity: 1;
}



#device-share-location {

    .text-1 {
        color: #BBBBBB;
        font-size: 19px;
    }

    .text-2 {
        color: #BBBBBB;
        font-size: 14px;
        font-weight: 300;
    }

    .arrow-1 {
        color: #BBBBBB;
        font-size: 7rem;
    }

    .title-header {
        font-size: 15px;
        font-weight: 600;
        color: #334257;
        line-height: initial;
    }

    .title-sub-header {
        font-size: 12px;
        font-weight: 400;
        color: #334257;

    }

}

#device-share-location-create,
#device-share-location-edit,
#events-management-observations,
#events-management-observations-form {

    .text-1 {
        font-size: 13px;
        font-weight: 600;
    }

    .text-2 {
        color: #BBBBBB;
        font-size: 14px;
        font-weight: 300;
    }

    .arrow-1 {
        color: #BBBBBB;
        font-size: 7rem;
    }

    .title-header {
        font-size: 15px;
        font-weight: 600;
        color: $color-text-800;
        line-height: initial;
    }

    .title-sub-header {
        font-size: 12px;
        font-weight: 400;
        color: $color-text-800;

    }

    .form-floating label {

        padding: 1px 14px !important;

    }

    .isDisabled {
        color: currentColor;
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
    }

    #toast-container * {
        color: red !important;
    }

    .card {

        padding-left: 20px !important;
        padding-right: 20px !important;

    }

    .btn-custom-token {

        height: 38px !important;
        width: 38px !important;
        text-align: center !important;
        border-radius: 50px !important;
        -webkit-border-radius: 50px !important;
        -moz-border-radius: 50px !important;
        -khtml-border-radius: 50px !important;
        font-size: 14px;
        line-height: 23px;

    }

    .form-input-token input {
        width: 100%;
        height: 38px !important;
        font-size: 9pt !important;
    }

    .handle-counter {
        display: flex;
        justify-content: space-between;
        @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.10));
        border-radius: 10px !important;
        -webkit-border-radius: 10px !important;
        -moz-border-radius: 10px !important;
        -khtml-border-radius: 10px !important;
        border: 1px solid silver !important;
    }

    .counter-minus,
    .counter-plus {
        text-align: center;
        line-height: 30px;
    }

    .counter-minus {
        background: transparent;
        border: none;
        font-size: 14px;
        border-radius: 10px 0px 0px 10px !important;
        -webkit-border-radius: 10px 0px 0px 10px !important;
        -moz-border-radius: 10px 0px 0px 10px !important;
        -khtml-border-radius: 10px 0px 0px 10px !important;
    }

    .counter-plus {
        background: transparent;
        border: none;
        font-size: 14px;
        border-radius: 0px 10px 10px 0px !important;
        -webkit-border-radius: 0px 10px 10px 0px !important;
        -moz-border-radius: 0px 10px 10px 0px !important;
        -khtml-border-radius: 0px 10px 10px 0px !important;
    }

    .handle-counter-input {
        color: #555 !important;
        background-color: $color-bg-000 !important;
        background-image: none !important;
        //border: 1px solid #ccc !important;
        padding: 6px 12px !important;
        text-align: center;
        height: 45px !important;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 0px 0px 0px 0px !important;
        -webkit-border-radius: 0px 0px 0px 0px !important;
        -moz-border-radius: 0px 0px 0px 0px !important;
        -khtml-border-radius: 0px 0px 0px 0px !important;

        font-size: 18px;
        font-weight: 600;
        opacity: 1.0 !important;

    }

    .btn-controls:disabled,
    .btn-controls:disabled:hover {
        background-color: transparent;
        cursor: not-allowed;
        opacity: 1.0 !important;
    }

    .handle-counter-input:disabled {
        color: $color-text-800 !important;
    }




}


#device-share-list {
    .text-list-1 {
        font-size: 13px;
    }

    .avatar-48 {
        height: 40px;
        width: 40px;
        font-size: 32px;
        line-height: 40px;
    }


}

/*
#device-edit-container .form-floating{
    position: relative;
}

#device-edit-container .form-floating input{
    width: 100%;
    height: 45px !important;
    border: silver 1px solid !important;
    background: white !important;
    font-size: 13pt !important;
}



#device-edit-container .form-floating input::placeholder{
    color: white !important;
}

#device-edit-container .form-floating .error_msg{
    display: none;
    background: white;
    color: red;
    position: absolute;
        bottom: 0;
        left: 0;
        padding: 3px;
        overflow: hidden;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        border: 1px solid transparent;
        transform-origin: 0 0;
        transition: opacity .1s ease-in-out,transform .1s ease-in-out;
        transform: translateY(10px) translateX(1.3rem);
}

#device-edit-container .form-floating.error .error_msg{
    display: block;
}

#device-edit-container .form-floating.error input{
    border: red 2px solid !important;
}



#device-edit-container .form-floating label{
    color: rgb(143,158,171);
    position: absolute;
        top: 0;
        left: 0;
        padding: 0.7rem 0.90rem;
        overflow: hidden;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        border: 1px solid transparent;
        transform-origin: 0 0;
        transition: opacity .1s ease-in-out,transform .1s ease-in-out;
        transform: translateY(0.5rem);
}


#device-edit-container .form-floating .options{
    position: absolute;
    left: 0px;
    top: 46px;
    opacity: 0;
    background: white;
    max-height: 0px;
    overflow: hidden;
    border-radius: 10px;
    transition: max-height 0.5s ease-in-out,opacity 0.5s;
    border: silver 1px solid;
    z-index: 99999;
    min-width: 100%;
}


#device-edit-container .form-floating .options ul{
    list-style: none;
    margin: 0px;
    padding: 0px;
}


#device-edit-container .form-floating .options ul li{
    padding: 8px;
}

#device-edit-container .form-floating .options ul li:hover{
    background: rgba(0,0,0,0.05);
}

#device-edit-container .form-floating input:focus{
    border: #202020 2px solid !important;
}

#device-edit-container .form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    color: #202020 !important;
    transform: scale(.95) translateY(-2.5rem) translateX(1.3rem) !important;
    background: transparent;
}

.form-floating>.form-control:focus~.options{
    opacity: 1 !important;
    max-height: 30vh !important;
    overflow: auto !important;
    transition: max-height 0.5s ease-in-out,opacity 0.3s !important;
}*/

.overlay-sidebar-devices {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #334257CC !important;
    z-index: 999;
    top: 0px;
    opacity: 1.5;
    left: 5.8em;
    @include calc('max-height', 100%, '100% - 0px');
    height: 100%;
    width: 100%;

    @media (min-width: $screen-sm-min) {
        width: 370px;
    }

    @media (min-width: $screen-md-min) {
        width: 302px;
    }

}

@media screen and (max-width: 500px) {

    #device-edit-container {
        //padding-bottom: 100px;
    }
}

.overlay-sidebar-devices-content {
    display: none;
    position: fixed;
    width: 100%;
    z-index: 999999;
    left: 5.8em;
    @include calc('max-height', 100%, '100% - 0px');
    top: 0px;
    padding: 20px;

    @media (min-width: $screen-sm-min) {
        width: 370px;
    }

    @media (min-width: $screen-md-min) {
        width: 302px;
    }
}

.overlay-sidebar-devices-close {
    display: none;
    width: 40px;
    height: 40px;
    position: fixed;
    right: 20px;
    bottom: 10px;
    background: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 22px;
    z-index: 99999999;
    text-align: center;
}


#overlay-sidebar-devices.show .overlay-sidebar-devices-close {
    display: block;
}

#overlay-sidebar-devices.show .overlay-sidebar-devices {
    display: block;
}

#overlay-sidebar-devices.show .overlay-sidebar-devices-content {
    display: block;
}

.btn-holder {
    position: absolute;
    margin-left: 0px;
    left: 0%;
    width: 100%;
    bottom: 20px;
}

.avatar-car-detail {
    font-size: 20px;
    padding-top: 5px;
    background: $color-bg-000;
}

#scrollLoading {
    text-align: center;
}

#device-detail-content {
    @media only screen and (max-width: 768px) {
        //margin-bottom: 100px;
    }
}