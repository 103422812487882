@import 'base';
@import 'icons';
@import 'header';
@import 'scrollbar';
@import 'sidebar';
@import 'navs';
@import 'navbars';
@import 'buttons';
@import 'input-groups';
@import 'list-group';
@import 'dropdown';
@import 'forms';
@import 'modals';
@import 'tables';
@import 'map';
@import 'pagination';
@import 'widgets';
@import 'groups';
@import 'panels';
@import 'loader';
@import 'sign-in';
@import 'dialog';
@import 'plans';
@import 'bottombar';
@import 'chat';
@import 'dashboard';
@import 'autocomplete-select';
@import 'thumbnails';
@import 'banner';
@import 'datatables';
@import 'utilities';
@import 'home';
@import 'objects-page';
@import 'menu';
@import 'device-modal';
@import '../mobile/mobile';
@import 'sidebar_devices';
@import 'right_bar';
@import 'right_events_management';
@import 'os_page';

@import 'users_objects';

@import 'app-clients-index';
@import 'assistance-page.scss';
@import 'cars-page-index';
@import 'events_management';
@import 'smartcam';
@import 'highcluster';
@import 'smart-center';
@import 'sms_form_modal';
@import 'sms_template_modal';