body.sign-in-layout {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .sign-in-text {
    color: #fff;
  }

  .panel {
    position: relative;
    background: none;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    @include box-shadow(0 0 15px rgba(0, 0, 0, 0.5));
  }

  .panel-body {
    position: relative;
  }

  .panel-background {
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
  }

  .app-links {
    margin: 20px 0;
  }
}