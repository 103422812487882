#right_events_management{

    .skeleton-show .skeleton_loading{
        background: #cfcece;
        height: 26px;
        border-radius: 6px;

        position: relative;
        overflow: hidden;

    }
 
    .skeleton-show .skeleton_loading:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(75deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 20%, rgba(255, 255, 255, 0.75) 60%, rgba(255, 255, 255, 0));;
        animation: shimmer 2s infinite;
        content: '';
    }

    .skeleton-show .skeleton_loading input,.skeleton-show .skeleton_loading label ,.skeleton-show .skeleton_loading .skeleton_hide{
        display: none !important;
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
    

    .action-buttons{
        height: 40px !important;
        width: 40px !important;
        line-height: 19px;
        font-size: 13px;
        @include box-shadow(0 0px 5px rgba(0,0,0,0.15));

        &.loader-wrap{            
            width: 44px !important;
            height: 44px !important;
            display: inline-block !important;
            position: relative !important;
            box-shadow: none !important;
        }

        .clicker {
            /* Inner circle */
            width: 40px;
            height: 40px; /* 20px smaller b/c of margin below */
            margin: 1px;
            background-color: #fff;
            border-radius: 60px;
            
            /* Overlays this circle on the .circle */
            z-index: 2;
            position: absolute;
            left: 0px;
            top: 0px;
            
            padding: calc(1rem - 1px);
            text-align: center;
            @include box-shadow(0 0px 5px rgba(0,0,0,0.15));
        }

        &.is-waiting{
            
            @include box-shadow(0 0px 5px rgba(0,0,0,0.15));

            .clicker{
                box-shadow: none !important;
            }
            .outer-circle{
                /** outer circle **/
                width: 44px;
                height: 44px;
                border-radius: 50%;
                
                /** image = wedge **/
                background-image: linear-gradient(45deg, 
                            transparent 0%,
                            transparent 20%,
                            $component-active-bg 30%,
                            $component-active-bg 70%, 
                            transparent 80%, 
                            transparent 100%);
                
                /*** outer circle position: under */
                z-index: 1;
                position: absolute;
                left: 0px;
                top: 0px;

                
                -webkit-animation: rotor 1.5s linear 0s infinite normal;
                -mox-animation: rotor 1.5s linear 0s infinite normal;
                -o-animation: rotor 1.5s linear 0s infinite normal;
                animation: rotor 1.5s linear 0s infinite normal;
            }
        }
    }

    .nav-default > li > a {
        background-color: transparent;
        border-color: transparent;
        font-weight: 300;
        font-size: 10px;

        @media only screen and (max-width: 768px) {
            font-size: 11px;
        
        }  
    }
    .nav-default > li.active:after {
        width: 100%;
        height: 3px;

       
    }

    .nav > li > a {
        padding: 10px 10px;
        @media only screen and (max-width: 768px) {
            padding: 10px 15px;
        
        }    
    }

    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
        color:$component-active-bg;
    }
    
    .nav-default {
      
        background: linear-gradient(180deg, darken(#ffffff, 0), darken(#f2f2f2, 0))

    }

    .nav-tabs {
     border-bottom: 0px solid transparent;   
     > li {
     display: table-cell;
     width: 1%;
     float: none;
     > a {
     text-align: center;
        }
    }
    }

    #userData{
        font-weight: 300;
    }

}