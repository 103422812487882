#sms_template_modal {

    #loader {
        border: 8px solid $color-bg-200;
        border-radius: 50%;
        border-top: 8px solid #3498db;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        margin-left: 50px;
        margin-top: 0px;

    }


    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }


    .odd tr td {
        height: 5px;
    }


    .filter-wrapper {
        padding: 24px;
        min-height: 152px;
        box-sizing: border-box;
        background-color: $color-bg-000;
        border-radius: 4px;
        box-shadow: 0 0 0 1px $color-bg-000;
    }


    .input_custom {
        background-color: $color-bg-200 !important;
        border-radius: 8px !important;
        font: 500 16px Lato !important;
        text-transform: none !important;
        color: #6b757e !important;
        height: 32px !important;
        font-size: 13px !important;
        box-sizing: border-box !important;
        cursor: pointer !important;
        border: none !important;

    }

    ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #6b757e !important;
    }

    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #6b757e !important;
        opacity: 1;
    }

    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #6b757e !important;
        opacity: 1;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #6b757e !important;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #6b757e !important;
    }

    ::placeholder {
        /* Most modern browsers support this now. */
        color: #6b757e !important;
    }



    /* Bootstrap 3 text input with search icon */

    .has-search .form-control-feedback {
        right: initial;
        left: 0;
        font-size: 16px;
        color: #6b757e;
    }

    .has-search .form-control {
        padding-right: 12px !important;
        padding-left: 40px !important;
    }

    .has-search-arrow {
        line-height: 40px !important;
    }

    .filter-search {
        padding: 8px !important;
        border-radius: 4px !important;
        font: 300 16px Roboto !important;
        text-transform: none !important;
        font-size: 14px !important;
        box-sizing: border-box !important;
        cursor: pointer !important;
    }

    .ml8 {
        margin-left: 8px;
    }

    .mr8 {
        margin-right: 8px;
    }

    .filter-wrapper .filter-list .filter-actions {
        outline: 0;
        font-size: 12px;
        margin-top: 12px;
        cursor: default;

    }

    .layout-row {
        flex-direction: row;
        box-sizing: border-box;
        display: inline-flex;
    }

    .filter-labels {
        color: #6b757e;
        margin-top: 5px;
    }

    .bootstrap-select>.dropdown-toggle {
        background-color: transparent !important;
        height: 32px !important;
        color: #6b757e !important;
    }

    .st-title,
    .st-title-small {
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: normal;
    }

    .st-title {
        font: 700 24px/28px Lato, sans-serif;
        -webkit-font-smoothing: antialiased;
    }


    .screen-info {
        margin-bottom: 13px;
    }

    .navigator-amount-title {
        padding-top: 8px;
    }

    .st-aux,
    .st-caption,
    .st-thin {
        color: #717171;
        font: 400 12px/16px Lato, sans-serif;
        letter-spacing: .3px;
    }

    .screen-info .icon-helper {
        margin-left: 8px;
        padding: 4px;
        width: 32px;
        height: 32px;
        background-color: $color-bg-200;
        color: #717171;
        border-radius: 16px;
        text-align: center;
        vertical-align: middle;
    }

    .icon_maintence {
        line-height: 24px;
    }

    .icon_filter {
        line-height: 27px;
    }

    table td.details-control:before {
        content: '>';

        cursor: pointer;
        font-size: 22px;
        color: #55a4be;
    }

    table tr.shown td.details-control:before {
        content: 'xx';
        color: black;
    }

    tbody {
        background: $color-bg-200;
        border-radius: 10px;
    }

    tbody tr td:first-child {
        border-left: 1px solid $color-bg-200;
    }

    tbody tr td:last-child {
        border-right: 1px solid $color-bg-200;
    }

    tbody tr:first-child td {
        border-top: 1px solid $color-bg-200;
    }

    tbody tr:last-child td {
        border-bottom: 1px solid $color-bg-200;
    }

    .table>thead>tr>th {
        vertical-align: bottom;
        border-bottom: none !important;
    }

    table tbody tr:first-child td:first-child {
        border-top-left-radius: 5px;
    }

    table tbody tr:first-child td:last-child {
        border-top-right-radius: 5px;
    }

    table tbody tr:last-child td:first-child {
        border-bottom-left-radius: 5px;
    }

    table tbody tr:last-child td:last-child {
        border-bottom-right-radius: 5px;
    }

    table.dataTable td.dt-control:before {
        height: 1em;
        width: 1em;
        display: inline-block;
        border: 0.15em solid $color-bg-200;

        box-sizing: content-box;
        text-align: center;
        text-indent: 0 !important;
        font-family: "Font Awesome 6 Pro", Courier, monospace;
        line-height: 1em;
        content: "\f078";
        line-height: 30px;

    }

    .st-expansion-footer {
        display: block;
        position: relative;
        line-height: 48px;
        min-height: 48px;
        padding: 0 24px;
        box-sizing: border-box;
        border-top: 1px solid $color-bg-200;
    }

    .m-3 {
        padding: 10px !important;
    }


    .text-center1 {
        vertical-align: middle !important;
        text-align: center !important;
    }

    .text-center2 {
        vertical-align: middle !important;
    }


    .modal-backdrop.in {
        opacity: 1.5 !important;
        filter: alpha(opacity=50);
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #334257CC !important;
    }

    .modal-content {
        -webkit-border-radius: 10px !important;
        -moz-border-radius: 10px !important;
        border-radius: 10px !important;
    }

    .modal-header {
        -webkit-border-top-left-radius: 7px;
        -webkit-border-top-right-radius: 7px;
        -moz-border-radius-topleft: 7px;
        -moz-border-radius-topright: 7px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .modal-footer {
        border-radius: 0px 0px 7px 7px !important;
    }

    .dataTables_scrollHeadInner,
    .table {
        width: 100% !important
    }

    .modal-body {
        padding-bottom: 30px !important;
    }

    .dataTables_paginate {
        float: right;
        padding-left: 0;
        margin: 3px 0 !important;
        border-radius: 0;
    }

    .dataTables_paginate .paginate_button.disabled,
    .dataTables_paginate .paginate_button.disabled:hover,
    .dataTables_paginate .paginate_button.disabled:focus {
        border-radius: 5px;
    }
}