.tab-content {
  .tab-pane {
    display: none;

    &.active {
      display: block;
    }
  }
}

.nav-tabs {
  >li {
    >a {
      margin-right: 0px;

      &.has-error {
        color: $state-danger-text;
        background: $state-danger-bg;
      }

      &.disabled {
        @include opacity(0.7);

        cursor: $cursor-disabled;
      }
    }

    &:last-child {
      padding-right: 1px;
    }
  }
}

.nav-names-break {
  @media only screen and (max-width: 480px) {
    /* Styles for mobile phones in portrait mode */
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  @media only screen and (min-width: 481px) and (max-width: 767px) {
    /* Styles for mobile phones in landscape mode */
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;

  }

  @media only screen and (min-width: 1025px) and (max-width: 1280px) {
    /* Styles for tablets in landscape mode */
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
}


.nav-default {
  background-color: $nav-tabs-bg;

  @media only screen and (max-width: 480px) {
    /* Styles for mobile phones in portrait mode */
    line-height: 1.5 !important;
    vertical-align: middle !important;
  }

  @media only screen and (min-width: 481px) and (max-width: 767px) {
    /* Styles for mobile phones in landscape mode */
    line-height: 1.5 !important;
    vertical-align: middle !important;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1280px) {
    /* Styles for tablets in landscape mode */
    line-height: 1.5 !important;
    vertical-align: middle !important;
  }

  >li {
    >a {
      color: $nav-link-color;
      background-color: $nav-link-bg;
      border-color: $nav-tabs-border-color;
      font-weight: 700;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 3px;
        left: 0;
        bottom: 0px;
        background: $component-active-bg;
        box-sizing: border-box;
      }
    }
  }
}

.nav-icons {
  background-color: inherit;

  >li {
    >a {
      line-height: $heading-height;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1.5em;

      color: $nav-link-color;

      &:hover {
        color: $brand-primary;
      }
    }

    &.active {
      >a {
        color: $brand-primary;
      }
    }
  }
}

.nav-tabs-justified,
.nav-tabs.nav-justified {
  >li>a {
    margin-right: -1px;
    margin-bottom: 0px;
  }
}