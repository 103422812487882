.dashboard-wrapper {
    display: flex;
    align-items: stretch;
}

.body-area {
    flex: 1;
    padding: 30px;
    background: #fff;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    overflow-x: hidden;
}

.main-pages>a>span {
    width: 0;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.3s all;
    font-size: 16px;
    margin-left: 10px;
}

.side-btn {
    font-size: 34px;
    padding: 20px 17px;
    cursor: pointer;
}

.main-pages {
    margin-top: 12vh;
}

.main-pages>a {
    display: flex;
    align-items: center;
    justify-content: start;
    color: #334257;
    font-size: 25px;
    text-decoration: none;
    margin: 10px 7px;
    margin-right: -2px;
}

.main-pages>a>i {
    height: 50px;
    width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.main-pages>a:hover>i,
.main-pages>a.active>i {
    background: #d4eaf7;
}

.user-infos {
    margin: 0 7px;
    margin-top: 80px;
    cursor: pointer;
}

.user-infos>i {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.user-infos>span {
    align-items: center;
    justify-content: center;
    font-size: 10px;
    gap: 4px;
    border-radius: 99px;
    padding: 0 5px;
    border: 1px solid #eee;
    line-height: 2;
    color: #2bb895;
    background: #fff;
    display: inline-flex;
    position: relative;
    top: -18px;
}

.user-infos>span>i {
    position: relative;
    top: 1px;
    font-size: 7px;
}

body.side-open div#Sidebar {
    width: 200px;
}

body.side-open .main-pages>a>span {
    width: 130px;
}

.action-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.action-header h3 {
    font-size: 24px;
    margin: 0;
    line-height: 1;
}

.action-btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.action-btns>a {
    height: 40px;
    width: 40px;
    @include button-variant-light($component-active-bg, $btn-primary-bg, $btn-primary-border);
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000014;
    //border: 1px solid $color-bg-300;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.map-list-tab>a {
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #334257;
    background: #fff;
    text-decoration: none;
}

.map-list-tab {
    display: flex;
    align-items: center;
}

.map-list-tab>a:last-child {
    border-radius: 0px 9px 9px 0px;
}

.map-list-tab>a:first-child {
    border-radius: 9px 0 0 9px;
}

.map-list-tab>a.active {
    background: #39A2DB;
    color: #fff;
}

.result-count {
    font-size: 14px;
    color: #585858;
    font-weight: 300;
    margin: 0 0 6px;
}

.result-count b {
    color: #2a2a2a;
    font-weight: 600;
}

.driver-img {
    position: absolute;
    height: 35px;
    width: 35px;
    top: 0;
    left: 0;
}

.car--box {
    display: block;
    padding: 20px;
    background: $color-bg-000;
    border-radius: 10px;
    gap: 15px;
    margin-bottom: 20px;
    cursor: pointer;
    padding-bottom: 0px;
    border: 1px solid $color-border-200;
    box-shadow: 0px 1px 4px #00000014;
}

.car--box-container {
    display: flex;
    align-items: start;
    padding: 0px;
    background: $color-bg-000;
    border-radius: 10px;
    gap: 15px;
    margin-bottom: 0px;
    cursor: pointer;
    padding-bottom: 0px;
    border: 1px solid transparent;
}

.car-image-area>img {
    height: 120px;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000014;

}

.round-img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000014;
    overflow: hidden;
    position: relative;
    border: 1px solid $color-border-100;
}

.spedometer {
    display: inline-flex;
    font-size: 12px;
    background: $color-bg-000;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid $color-border-100;
    border-radius: 5px;
    padding: 2px 10px;
    align-items: center;
    //gap: 5px;
    position: relative;
    top: -12px;
}

.car-image-area {
    width: 120px;
    text-align: center;
    position: relative;
}

[online="1"] .spedometer:before {
    background: #2bb895;
    width: 10px;
}

.spedometer:before {
    content: '';
    height: 10px;
    display: inline-block;
    border-radius: 50%;
}

[online="0"] .spedometer:before {
    //background: #D63031;
    //width: 10px;
}

.driver-img>img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    object-fit: cover;
}

.driver-img:before {
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    bottom: 0;
    left: 5px;
    border: 1px solid #eee;
}

[online="1"] .driver-img:before {
    background: #2bb895;
}

[online="0"] .driver-img:before {
    background: #D63031;
}

.car--info {
    flex: 1;
}

.car--info h5 span {
    display: block;
    font-size: 14px;
    font-weight: 300;
    color: $color-text-800;
}

.car--info h5 {
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 10px;
    font-weight: 600;
    color: $color-text-800;
}


.conect-sd {
    padding: 3px 10px;
    box-shadow: 0px 0px 4px #00000014;
    border: 1px solid $color-border-100;
    border-radius: 5px;
    font-size: 12px;
    color: $color-text-800;
    font-weight: 300;
}

.conect-sd>i {
    font-size: 14px;
    margin-right: 5px;
    position: relative;
    top: 1px;
    color: $color-text-800;
}

.conect-sd>b {
    font-weight: 500;
    color: $color-text-800;
    font-size: 13px;
}

.locatisn {
    display: flex;
    //align-items: center;
    gap: 5px;
    font-size: 12px;
    line-height: 1.5;
    margin: 10px;
    margin: 0px;
    margin-bottom: 15px;
    //margin-top: 15px;
}

.locatisn p {
    font-size: 9px;
    //line-height: 1.5;
    margin: 0;
    font-weight: 400;
    color: $color-text-800;
}

.speed-text-update {
    font-weight: 400;
    color: $color-text-800;
}

.locatisn>i {
    color: #39a2db;
    position: relative;
    top: 5px;
}

.carros-lists {
    overflow-y: scroll;
    height: 80vh;
    margin-right: 0;
    padding-right: 0;
}

.carros-lists::-webkit-scrollbar {
    width: 0;
}

.carros-lists::-webkit-scrollbar-track {
    background: transparent;
}

.carros-lists::-webkit-scrollbar-thumb {
    background-color: #334257;
    border-radius: 20px;
}

.car--box:last-child {
    margin-bottom: 0;
}

.car--info h5>i {
    color: #FDCB6E;
}

.car--box.active {
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #39A2DB;
}

.detail-header h2 {
    font-size: 34px;
    line-height: 1;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin: 0;
}

.detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
}

.detail-header h2 span {
    font-size: 16px;
    font-weight: 300;
}

.align-right-sd {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 30px;
}

.carros-driver>img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #D9D9D9;
}

.carros-driver>span {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    gap: 5px;
    line-height: 0;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
}

[online="1"] .carros-driver>span:before {
    background: #2bb895;
}

[online="0"] .carros-driver>span:before {
    background: #D63031;
}

.carros-driver>span:before {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.modal-starred-inputarea {
    align-items: center;
    display: flex;
    padding: 16px;
}

.modal-starred-inputarea input {
    background: $input-bg;
    border: 2px solid $component-active-bg;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    outline: none;
    padding: 0px 8px;
    padding-left: 10px;
}

.modal-starred-inputarea input::placeholder {
    color: #444;
}

.modal-starred-inputarea i {
    position: relative;
    right: 32px;
    width: 0;
}

.action-caros>a {
    height: 45px;
    width: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    color: #334257;
    font-size: 18px;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #D9D9D9;
}

.action-caros {
    display: inline-flex;
    align-items: center;
    gap: 15px;
}

.car-image-detail>img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px solid #D9D9D9;
    display: block;
    margin: auto;
    object-fit: cover;
}

.car-image-detail {
    text-align: center;
}

.tank-condition {
    padding: 25px;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
}

.tank-condition>p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
}

.tank-condition>p>a {
    color: #39A2DB;
    text-decoration: none;
    font-weight: 500;
    font-size: 13px;
}

.dfine-lg {
    font-size: 25px;
    margin: 0 0 20px;
    line-height: 1;
}

.dfine-lg span {
    color: #2bb895;
}

.in-progrss {
    height: 10px;
    width: 100%;
    background: #eee;
    border-radius: 99px;
    position: relative;
    overflow: hidden;
}

.in-progrss>span {
    position: absolute;
    height: 100%;
    background: #2bb895;
    border-radius: 99px;
}

.detl-flex {
    display: flex;
    align-items: center;
    margin: 20px 0;
    justify-content: space-between;
}

.detl-flex>div {
    flex: 1;
    text-align: center;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 300;
}

.detl-flex>div>span {
    display: block;
    font-weight: 700;
}

.tank-condition .text-end>a {
    color: #334257;
    text-decoration: none;
    font-size: 13px;
}

.average-spd {
    line-height: 1;
    font-size: 25px;
    font-weight: bold;
}

.your-avrg {
    font-size: 18px;
    line-height: 1;
    margin: 26px 0;
}

.your-avrg>span {
    font-size: 22px;
    color: #2bb895;
}

.about-graph {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 20px;
    word-spacing: 4px;
    flex-flow: wrap;
    gap: 10px 0;
}

.map-iframe iframe {
    width: 100%;
    height: 350px;
}

.location-wrot {
    margin: 0 0 10px 0;
    font-size: 14px;
}

.location-wrot>i {
    color: #39a2db;
}

.body-area::-webkit-scrollbar {
    width: 0;
}

.body-area::-webkit-scrollbar-track {
    background: transparent;
}

.body-area::-webkit-scrollbar-thumb {
    background-color: #334257;
    border-radius: 20px;
}

.syncron {
    text-align: right;
    margin-top: 10px;
}

.hoje-box {
    padding: 20px;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 0 20px;
}

.hoje-box>a {
    color: #39a2db;
    text-decoration: none;
}

.hoje-cont {
    flex: 1;
}

.hoje-time {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 20px;
}

.hoje-cont p {
    margin: 0;
    font-size: 14px;
}

.hoje-cont .d-flex {
    gap: 10px;
}

.hoje-cont i {
    font-size: 18px;
    width: 30px;
}

.hoje-cont .d-flex:not(:last-child) {
    margin-bottom: 10px;
}

.tim-linsd {
    padding: 20px 25px;
    border-left: 1px solid #ddd;
    margin-left: 10px;
    word-spacing: 4px;
    padding-right: 0;
}

.hode-list {
    height: 350px;
    overflow-y: scroll;
}

.hode-list::-webkit-scrollbar {
    width: 0;
}

.hode-list::-webkit-scrollbar-track {
    background: transparent;
}

.hode-list::-webkit-scrollbar-thumb {
    background-color: #334257;
    border-radius: 20px;
}

.body-area.loading {
    overflow: hidden;
}

.body-area.loading:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(../images/loading.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    z-index: 99;
    filter: hue-rotate(15deg);
}

.hoje-box:last-child {
    margin: 0;
}

.carros-detail {
    display: none;
}

.map-full-screen {
    height: calc(100% + 53px);
    width: calc(100% + 60px);
    margin: -30px;
    display: none;
}

.map-full-screen>iframe {
    height: 100%;
    width: 100%;
}

.body-area.map .carros-detail {
    display: none;
}

.body-area.map .map-full-screen {
    display: block;
}

.body-area.det .carros-detail {
    display: block;
}

.body-area.det .map-full-screen {
    display: none;
}

.search-area h3 {
    font-size: 24px;
    margin: 0;
    line-height: 1;
    display: inline-block;
}

.close-this {
    float: right;
    color: #39A2DB;
    text-decoration: none;
}

.search-input {
    display: flex;
    width: 100%;
    clear: both;
    margin-top: 30px;
    position: relative;
    margin-bottom: 30px;
}

.search-input>input {
    width: 100%;
    background: #fff;
    border: 1px solid #39A2DB;
    border-radius: 5px;
    height: 50px;
    padding: 0 15px;
    color: #334257;
    font-weight: 500;
    outline: none;
}

.search-input>input::placeholder {
    color: #334257;
}

.search-input>button {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    width: 50px;
    background: transparent;
    border: 0;
    color: #334257;
    font-size: 20px;
}

.search-area {
    display: none;
}

.action-area.open-search .carros-area {
    display: none;
}

.action-area.open-search .search-area {
    display: block;
}

.action-area.open-filter .carros-area {
    display: none;
}

.action-area.open-filter .filter-area {
    display: block;
    height: 100%;
    position: relative;
}

.filter-area h3 {
    font-size: 24px;
    margin: 0;
    line-height: 1;
    display: inline-block;
}

.filter-drop {
    margin-top: 40px;
    position: relative;
}

.filter-drop>select {
    width: 100%;
    height: 50px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    appearance: none;
    padding: 0 20px;
    outline: none;
}

.filter-drop>i {
    position: absolute;
    right: 20px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    pointer-events: none;
}

.checkboxes-wrap {
    margin-top: 40px;
}

.check-cont h6 {
    margin: 0;
    font-weight: bold;
    font-size: 22px;
    line-height: 1;
}

.check-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #E5E5EA;
    padding: 10px 0;
}

.check-cont p {
    margin: 0;
}

.check-btn {
    position: relative;
}

.check-btn>input {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.check-btn>span {
    height: 30px;
    display: inline-flex;
    width: 50px;
    background: #dbdbdb;
    border-radius: 99px;
}

.check-btn>span:before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    background: white;
    border-radius: 50%;
    left: 3px;
    top: 3px;
    transition: 0.3s all;
}

.check-btn>input:checked+span:before {
    left: 22px;
}

.check-btn>input:checked+span {
    background: #39A2DB;
}


.range-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
    box-shadow: 0 0 5px 0 #0000004b;
}

.range-slider__range {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: #d7dcdf;
    outline: none;
    padding: 0;
    margin: 24px 0px;
    z-index: 100;
}

.filter-area>a.btn {
    height: 60px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    background: #39A2DB;
    color: #fff;
    font-size: 22px;
}

.filter-area {
    display: none;
}

.loading-gif {
    height: 67vh;
    align-items: center;
    justify-content: center;
    display: none;
}

.result-search .carros-lists {
    display: none;
}

.result-search.loading .carros-lists {
    display: none;
}

.result-search.loading .loading-gif {
    display: flex;
}

.result-search.showw .loading-gif {
    display: none;
}

.result-search.showw .carros-lists {
    display: block;
}

@media only screen and (min-width: 1366px) and (max-width: 1399px) {
    .detail-header h2 {
        font-size: 27px;
    }

    .action-caros {
        gap: 10px;
    }

    .action-caros>a {
        height: 35px;
        width: 35px;
        font-size: 15px;
    }

    .car-image-detail>img {
        height: 170px;
        width: 170px;
    }

    .about-graph {
        font-size: 12px;
    }

    .body-area {
        flex: 1;
        padding: 30px 15px;
    }

    .location-wrot {
        margin: 0 0 10px 0;
        font-size: 12px;
    }

    .action-area {
        width: 400px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
    .detail-header h2 {
        font-size: 27px;
    }

    .action-caros {
        gap: 10px;
    }

    .action-caros>a {
        height: 35px;
        width: 35px;
        font-size: 15px;
    }

    .car-image-detail>img {
        height: 170px;
        width: 170px;
    }

    .about-graph {
        font-size: 12px;
    }

    .body-area {
        flex: 1;
        padding: 30px 15px;
    }

    .location-wrot {
        margin: 0 0 10px 0;
        font-size: 12px;
    }

    .action-area {
        width: 400px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}


.text-truncate-container {
    width: 250px;
}

.text-truncate-container p {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


@media only screen and (max-width: 767px) {

    //.locatisn {
    //    margin: 10px;
    //}
}

.sensors-text {
    color: $color-text-800;
}

.border-left-history {
    border-right: $color-text-800 1px solid;
    height: 20px;
    width: 20px;
}