//
// Checkboxes
// --------------------------------------------------
@mixin checkbox-variant($parent, $color, $label: 'label') {
  #{$parent} input[type="checkbox"]:checked + #{$label},
  #{$parent} input[type="radio"]:checked + #{$label} {
    &::before {
      color: #fff;
      background-color: $color;
    }
    &::after{
      background-color: $color;
      border-color: $color;
    }
  }
}

@mixin checkbox-container($selector) {
    #{$selector} {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-left: 0;
      margin-bottom: 0;

      &::before{
        content: '\0000';
        position: relative;
        z-index: 1;
        left: 0;
        width: ($checkbox-size - 2);
        height: ($checkbox-size - 2);
        display: inline-block;
        vertical-align: middle;
        color: transparent;
        background-color: $checkbox-background-color;
        @extend  .rounded-2;  

        margin: 1px;
        margin-right: 5px;
        padding: $checkbox-padding;
        font-size: $checkbox-font-size;
        line-height: 1;

        @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
      }

      &::after{
        position: absolute;
        top: 0;
        left: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        content: '\0000';
        color: transparent;
        border: 1px solid $checkbox-border-color;
        border-radius: 0;
        @extend  .rounded-2  
      }
    }
}
@mixin checkbox-checked($selector){
  #{$selector}::before{
    font-family: $checkbox-font-family;
    content: $checkbox-check-icon;
  }
}

@mixin checkbox($parent, $label) {
  #{$parent} {
    line-height: 1;
    position: relative;
    padding-left: 0;

    @include checkbox-container($label);

    input[type="checkbox"],
    input[type="radio"] {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      cursor: pointer;
      opacity: 0;

      &:focus + #{$label}::before {
        outline: 0;
      }

      @include checkbox-checked('&:checked + #{$label}');

      &:checked:disabled + #{$label}::before {
        background-color: inherit;
      }

      &:disabled + #{$label} {
        opacity: 0.45;

        &::before {
          background-color: $input-bg-disabled;
          cursor: not-allowed;
        }
      }

    }

    &.checkbox-inline {
      margin-top: 0;
    }
  }
}

// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
  #{$parent} input[type="radio"]{
    + label{
      &::after{
        //background-color: $color;
      }
    }
    &:checked + label{
      &::before {
        background-color: $color;
      }
      &::after{
        border-color: $color;
      }
    }
  }
}
