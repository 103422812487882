$color-bg-000: #ffffff !default;
$color-bg-100: #f9f9f9 !default;
$color-bg-200: #eeeeee !default;
$color-bg-300: #f4f5f7 !default;
$cinzinha: #EEEEEE !default;

$color-text-000: #ffffff !default;
$color-text-100: #202020 !default; //darkest
$color-text-200: #555555 !default; //darker
$color-text-300: #595959 !default; //dark
$color-text-400: #666666 !default; //normal
$color-text-500: #777777 !default; //ligth
$color-text-600: #999999 !default; //ligther
$color-text-700: #aaaaaa !default; //
$color-text-800: #cccccc !default;
$color-text-900: #dddddd !default;
$color-text-1000: #d7d7d7 !default;

$color-border-100: #d9d9d9 !default;
$color-border-200: #e9e9e9 !default;
$color-shadow: #cccccc !default;

/*
$base-bg:                #ffffff !default;
$base-bg-darker:         #eeeeee !default;
$base-bg-dark:           #f9f9f9 !default;
$base-border:            #d9d9d9 !default;
$base-color:             #202020 !default;
*/

$gray-base: #202020 !default;
$gray-darker: #555555 !default;
$gray-dark: #777777 !default;
$gray: #dddddd !default;
$gray-light: #eeeeee !default;
$gray-lighter: #f9f9f9 !default;

$zindex-navbar: 1000 !default;
$zindex-dropdown: 1000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-modal-background: 1040 !default;
$zindex-modal: 1050 !default;


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');
$font-family-sans-serif: 'Lato', sans-serif !default;


$font-size-base: 12px !default;
$font-size-smaller: $font-size-base - 2 !default;
$font-size-small: $font-size-base - 1 !default;
$font-size-large: $font-size-base + 1 !default;
$font-size-larger: $font-size-base + 2 !default;

$body-bg: $cinzinha !default;
$text-color: $color-text-100 !default;

$component-active-color: $color-text-000 !default;
$component-active-bg: $brand-primary !default;
$color-status-on: green !default;

//** headings
$heading-height: 38px !default;
$heading-color: $color-text-500 !default;
$heading-bg: $color-bg-100 !default;

//** icon
$icon-color: $color-text-900 !default;

//** borders
$border-radius-base: 0 !default;
$border-radius-large: 0 !default;
$border-radius-small: 2 !default;

//** navbars
$navbar-height: 42px !default;
$navbar-margin-bottom: 0 !default;
$navbar-border-radius: 0 !default;

$navbar-default-size: $font-size-large !default;
$navbar-default-bg: $color-bg-000 !default;
$navbar-default-border: $color-border-100 !default;
$navbar-default-color: $color-text-100 !default;
$navbar-default-brand-color: $navbar-default-color !default;
$navbar-default-brand-hover-color: $brand-primary !default;
$navbar-default-brand-hover-bg: $navbar-default-bg !default;
$navbar-default-link-color: $navbar-default-color !default;
$navbar-default-link-hover-color: $brand-primary !default;
$navbar-default-link-hover-bg: $color-bg-100 !default;
$navbar-default-link-active-color: $brand-primary !default;
$navbar-default-link-active-bg: $navbar-default-link-hover-bg !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;
$navbar-default-toggle-border-color: $navbar-default-border !default;
$navbar-default-toggle-hover-bg: $navbar-default-link-hover-bg !default;
$navbar-default-toggle-icon-bar-bg: $brand-primary !default;
$navbar-default-icon-color: $icon-color !default;

$navbar-main-size: $font-size-large !default;
$navbar-main-bg: $color-bg-000 !default;
$navbar-main-border: $color-border-100 !default;
$navbar-main-color: $color-text-100 !default;
$navbar-main-brand-color: $navbar-main-color !default;
$navbar-main-brand-hover-color: $brand-primary !default;
$navbar-main-brand-hover-bg: $navbar-main-bg !default;
$navbar-main-link-color: $navbar-main-color !default;
$navbar-main-link-hover-color: $brand-primary !default;
$navbar-main-link-hover-bg: $color-bg-100 !default;
$navbar-main-link-active-color: $brand-primary !default;
$navbar-main-link-active-bg: $navbar-main-link-hover-bg !default;
$navbar-main-link-disabled-color: #ccc !default;
$navbar-main-link-disabled-bg: transparent !default;
$navbar-main-toggle-border-color: $navbar-main-border !default;
$navbar-main-toggle-hover-bg: $navbar-main-link-hover-bg !default;
$navbar-main-toggle-icon-bar-bg: $brand-primary !default;
$navbar-main-icon-color: $icon-color !default;

//** navs
$nav-link-padding: 10px 15px !default;
$nav-link-bg: $color-bg-200 !default;
$nav-link-hover-bg: $color-bg-000 !default;
$nav-link-color: $color-text-500 !default;

$nav-disabled-link-color: $color-text-700 !default;
$nav-disabled-link-hover-color: $color-text-700 !default;

//== Tabs
$nav-tabs-bg: $color-bg-100 !default;
$nav-tabs-border-color: transparent !default;
$nav-tabs-link-hover-border-color: $nav-link-hover-bg !default;

$nav-tabs-active-link-hover-bg: $color-bg-000 !default;
$nav-tabs-active-link-hover-color: $color-text-100 !default;
$nav-tabs-active-link-hover-border-color: $nav-tabs-active-link-hover-bg !default;

$nav-tabs-justified-link-border-color: $color-border-100 !default;
$nav-tabs-justified-active-link-border-color: $nav-tabs-active-link-hover-bg !default;

//== Panels
$panel-bg: $color-bg-000 !default;
$panel-body-padding: 15px !default;
$panel-heading-padding: 9px 15px !default;
$panel-footer-padding: $panel-heading-padding !default;
$panel-border-radius: $border-radius-base !default;

$panel-inner-border: $color-border-100 !default;
$panel-footer-bg: $color-bg-100 !default;

$panel-default-text: $gray-dark !default;
$panel-default-border: $color-border-100 !default;
$panel-default-heading-bg: $color-bg-100 !default;

$widget-content-background-color: $color-bg-000 !default;
$widget-content-color: $color-text-500 !default;
$widget-content-icon-color: $color-text-800 !default;
$widget-col-background-color: #f9f9f9 !default;

//== Forms
$form-group-margin-bottom: 10px !default;
$input-bg: $color-bg-100 !default;
$input-bg-disabled: $color-bg-200 !default;
$input-color: $color-text-200 !default;
$input-color-focus: $color-text-100 !default;
$input-border: transparent !default;
$input-border-focus: $color-border-100 !default;
$input-bg-focus: $color-bg-000 !default;
$input-color-placeholder: $color-text-800 !default;
$input-group-addon-bg: $input-bg !default;
$input-group-addon-border-color: $input-border !default;
$cursor-disabled: not-allowed !default;

//== Tables
$table-cell-padding: 12px !default;
$table-condensed-cell-padding: 3px !default;
$table-bg: transparent !default;
$table-bg-accent: $color-bg-200 !default;
$table-bg-hover: $color-bg-100 !default;
$table-bg-active: $table-bg-hover !default;
$table-border-color: $color-border-100 !default;

$table-list-head-color: $color-text-500 !default;
$table-list-head-bg: $color-bg-200 !default;

//== Dropdowns
//** Background for the dropdown menu.
$dropdown-bg: $color-bg-000 !default;
$dropdown-border: $color-border-100 !default;
$dropdown-fallback-border: $color-border-100 !default;
$dropdown-divider-bg: $color-border-100 !default;
$dropdown-link-color: $color-text-500 !default;
$dropdown-link-hover-color: $color-text-500 !default;
$dropdown-link-hover-bg: $color-bg-100 !default;
$dropdown-link-active-color: $dropdown-link-hover-color !default;
$dropdown-link-active-bg: $dropdown-link-hover-bg !default;
$dropdown-link-disabled-color: $color-text-700 !default;
$dropdown-header-color: $dropdown-link-color !default;

$dropdown-icon-color: $color-text-900 !default;
$dropdown-icon-hover-color: $brand-primary !default;
$dropdown-icon-font-size: inherit !default;

$dropdown-padding-horizontal: 13px !default;
$dropdown-padding-vertical: 6px !default;
$dropdown-font-size: $font-size-small !default;

//== Pagination
$pagination-color: $color-text-100 !default;
$pagination-bg: $color-bg-000 !default;
$pagination-border: transparent !default;

$pagination-hover-color: $pagination-color !default;
$pagination-hover-bg: $color-bg-100 !default;
$pagination-hover-border: $pagination-border !default;

$pagination-active-color: $brand-primary !default;
$pagination-active-bg: $color-bg-100 !default;
$pagination-active-border: $pagination-border !default;

$pagination-disabled-color: $color-text-700 !default;
$pagination-disabled-bg: $pagination-bg !default;
$pagination-disabled-border: $pagination-border !default;

//** Thumbnails
$thumbnail-padding: 3px !default;
$thumbnail-bg: $color-bg-100 !default;
$thumbnail-border: $color-border-100 !default;
$thumbnail-border-radius: $border-radius-small !default;

//** Buttons
$btn-default-color: $color-text-500 !default;
$btn-default-bg: $color-bg-200 !default;
$btn-default-border: $btn-default-bg !default;

//** Panels
$panel-title-size: $font-size-larger !default;
$panel-title-icon-size: inherit !default;
$panel-title-icon-color: $brand-primary !default;

//** Groups
$group-padding-horizontal: 8px !default;
$group-heading-padding-vertical: 7px !default;
$group-heading-padding-horizontal: $group-padding-horizontal !default;
$group-item-padding-vertical: 5px !default;
$group-item-padding-horizontal: $group-padding-horizontal !default;

$group-heading-font-size: $font-size-large !default;
$group-heading-bg: $color-bg-100 !default;
$group-heading-hover-bg: $color-bg-200 !default;
$group-heading-color: $color-text-100 !default;
$group-heading-hover-color: $color-text-100 !default;
$group-heading-collapse-color: $color-text-500 !default;
$group-heading-collapse-hover-color: $color-text-500 !default;

$group-cell-padding-left: 5px !default;
$group-cell-padding-right: 0px !default;

$group-list-font-size: inherit !default;
$group-list-bg: inherit !default;
$group-list-hover-bg: $color-bg-100 !default;
$group-list-color: inherit !default;
$group-list-hover-color: inherit !default;

$group-collapse-status-color: $color-text-800 !default;
$group-collapse-status-hover-color: $color-text-800 !default;
$group-collapse-status-collapse-color: $brand-primary !default;
$group-collapse-status-collapse-hover-color: $brand-primary !default;

//** Map Controls
$map-controls-btn-bg: $color-bg-000 !default;
$map-controls-btn-color: $color-text-200 !default;
$map-controls-btn-hover-bg: $map-controls-btn-bg !default;
$map-controls-btn-hover-color: $map-controls-btn-color !default;
$map-controls-btn-active-bg: $component-active-bg !default;
$map-controls-btn-active-color: $component-active-color !default;

//** Modals
$modal-content-bg: $color-bg-000 !default;
$modal-header-bg: $color-bg-100 !default;
$modal-footer-bg: $color-bg-100 !default;
$modal-header-border-color: transparent !default;

//** Popups
$popup-header-bg: $color-bg-100 !default;
$popup-body-bg: $color-bg-000 !default;
$popup-header-border-color: transparent !default;
$popup-inner-padding: 8px;
$popup-header-padding: 10px;
$popup-title-color: $color-text-100 !default;
$popup-title-font-size: $font-size-large;
$popup-body-color: $color-text-300 !default;
$popup-body-font-size: $font-size-small;

//** Scrollbar
$scrollbar-size: 6px !default;
$scrollbar-track-bg: $color-bg-100 !default;
$scrollbar-track-border: $color-bg-000 !default;
$scrollbar-thumb-bg: $color-bg-300 !default;
$scrollbar-thumb-border: $color-bg-000 !default;


$sidebar-bg: $cinzinha !default;
$sidebar-padding-horizontal: 10px !default;
$sidebar-padding-vertical: 8px !default;
$sidebar-padding: $sidebar-padding-vertical $sidebar-padding-horizontal !default;
$sidebar-tab-header-padding: $sidebar-padding !default;
$sidebar-tab-footer-padding: $sidebar-padding !default;

//** Checkbox
$checkbox-font-family: 'icomoon' !default;
$checkbox-check-icon: '\e90b' !default;
$checkbox-background-color: $color-bg-000 !default;
$checkbox-border-color: $color-border-100 !default;
$checkbox-size: 18px !default;
$checkbox-font-size: 10px !default;
$checkbox-padding: (
  $checkbox-size - $checkbox-font-size - 2) / 2;

$bg: #e8ebf2;
$off: #8b8b8b;
$switch: #eee;
$shadow: #6f6f6f;
$on: #9bc148;

//** Borders


$border-width: 1px;
$border-widths: (
  1px,
  2px,
  3px,
  4px,
  55px
);

$border-color: $color-bg-100;

$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;
$border-radius-pill: 50rem;


:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 0, 184, 148;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #{$color-bg-100};
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bg-brand-primary: #{$brand-primary};
  --bg-brand-color: #{$text-color};
  --bg-brand-body-background: #{$color-bg-000};
  --bg-brand-color-100: #{$color-bg-100};
}