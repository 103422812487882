#loading,
.loading{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  color: $brand-primary;
}

#loading {
  display: table;
  vertical-align: middle;
  background-color: $color-bg-000;
}
#loading,
.loading{
  .backdrop {
    background-color: $color-bg-000;
    opacity: 0.5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .outter {
    display: table;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }

  .middle {
    display: table-cell;
    vertical-align: middle;
  }

  .inner {
    margin-left: auto;
    margin-right: auto;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg) ;
    transform: rotate(360deg); }
}

.loader div { box-sizing: border-box!important }
  .loader > div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    width: 35px;
    content: " ";
    height: 35px;
    left: 0px;
    top:  0px;
    border-radius: 50%;
    border: 5px solid #000;
    border-color: $brand-primary transparent $brand-primary transparent;
    animation: rotate 1s linear infinite;
  }
  .loader > div:nth-child(2) { border-color: transparent }
  .loader > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .loader > div:nth-child(2) div:before, .loader > div:nth-child(2) div:after { 
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    top: -5px;
    left: 10px;
    background: $brand-primary;
    border-radius: 50%;
    box-shadow: 0 30px 0 0 $brand-primary;
  }
  .loader > div:nth-child(2) div:after { 
    left: -5px;
    top: 10px;
    box-shadow: 30px 0 0 0 $brand-primary;
  }
  
  .loader {
    display: block;
    position: relative;
    margin: auto;
    height: 35px;
    width: 35px;
    transform: translateZ(0) scale(0.82);
    backface-visibility: hidden;
    transform-origin: 0 0;
    

    &.large {
      height: 60px;
      width: 60px;
      transform: translateZ(0) scale(1);
  
      &:before{
        height: 60px;
        width: 60px;
        border-width: 3px;
      }
      &:after {
        top: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
        border-width: 3px;
      }
    }
  
    &.small {
      height: 20px;
      width: 20px;
  
      &:before{
        height: 20px;
        width: 20px;
        border-width: 1px;
      }
      &:after {
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-width: 1px;
      }
    }
  }
  .loader div { box-sizing: content-box; }

  

  