.tab-pane {
  @extend .fadeIn;
  @extend .animated;
}

.group-heading>.group-title {
  text-align: center;
}

.sidebar-bg-primary-detail {
  background-color: $color-bg-000 !important;
}

.sidebar-bg-primary-edit {
  background-color: $color-bg-000 !important;
}

#sidebar {
  font-size: unset !important;
  position: fixed;
  z-index: 1005;
  bottom: 0;
  left: 5.8em;
  top: 0;
  background-color: $sidebar-bg;
  @include calc('max-height', 100vh, '100vh - 0px');
  @include box-shadow(0px 2px 20px 12px rgba(0, 0, 0, .14));
  height: 100vh;
  width: 100%;
  /* Disable bounces for a specific element with the class "scrollable" */
  overscroll-behavior: none;

  @media (min-width: $screen-sm-min) {
    width: 400px;
  }

  @media (min-width: $screen-md-min) {
    width: 400px;
  }

  .btn-collapse {
    @extend .collapse-left;
  }

  &.collapsed {
    width: 0;
    @include transition(width 0.5s ease);

    .sidebar-content {
      overflow: hidden;

      .group {
        display: none;
      }
    }

    .btn-collapse {
      @extend .collapsed;
    }
  }

  .sidebar-content {
    background: $sidebar-bg;
    width: 100%;
    height: 95%;
    max-width: 100%;
  }

  /*.nav-tabs {
     > li {
       display: table-cell;
       width: 1%;
       float: none;

       > a {
         text-align: center;
       }
     }
  }*/

  .tab-pane-header {
    padding: $sidebar-tab-header-padding;
  }

  .tab-pane-body {
    overflow-y: auto;
    position: relative;

    @extend .scrollbar;
  }

  .device-list-scroll {
    overflow-y: auto;
    position: relative;

    @extend .scrollbar;
  }


  .tab-pane-footer {
    padding: $sidebar-tab-footer-padding;
  }

  .no-results {
    margin: 5px;
    text-align: center;
  }

  #events_tab {
    .datetime {

      .time,
      .date {
        display: block;
        font-size: 0.9em;
      }
    }

    .tab-pane-body>table {
      >thead>tr {

        >th,
        >td {
          &:first-child {
            padding-left: $sidebar-padding-horizontal;
          }

          &:last-child {
            padding-right: $sidebar-padding-horizontal;
          }
        }
      }
    }
  }

  #geofencing_create,
  #geofencing_edit,
  #routes_create,
  #routes_edit,
  #map_icons_create,
  #map_icons_edit {
    .tab-pane-body {
      padding: $sidebar-padding;
    }
  }
}

.history {
  padding: 5px 0;
  background-color: $color-bg-100;

  -moz-box-shadow: inset 0 10px 10px -10px $color-shadow, inset 0 -10px 10px -10px $color-shadow;
  -webkit-box-shadow: inset 0 10px 10px -10px $color-shadow, inset 0 -10px 10px -10px $color-shadow;
  box-shadow: inset 0 10px 10px -10px $color-shadow, inset 0 -10px 10px -10px $color-shadow;

  .table {
    margin-bottom: 0;

    tr.active>td {
      background: $color-bg-000;
    }
  }
}

#ajax-events .table tbody,
#ajax-history .table tbody {
  font-size: 11px;
}


#mydiv {
  position: absolute;
  z-index: 900;
  bottom: 0;
  left: 0;
  text-align: none;
  max-height: 90%;
  max-height: -webkit-calc(90% - 90);
  max-height: -moz-calc(90% - 90);
  max-height: -o-calc(90% - 90);
  max-height: calc(90% - 90px);
  height: 100%;


}

#show-bar {
  padding: 10px;
  cursor: grab;
  z-index: 10;
  background-color: $component-active-bg;
  color: #fff;
  cursor: -webkit-grab;
  cursor: -moz-grab;

}

#mydivheader {
  padding: 10px;
  cursor: grab;
  z-index: 10;
  background-color: $component-active-bg;
  color: #fff;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

#mydivheader2 {
  padding: 10px;
  cursor: grab;
  z-index: 10;
  background-color: $component-active-bg;
  color: #fff;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.show_objects {
  background-color: $component-active-bg;
  color: #fff;
  @include box-shadow(3px 0px 7px rgba(0, 0, 0, .175));


}




* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: #c3c3c3;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

.u-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.u-input {
  outline: 0;
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  height: 35px;
  font-size: 12px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
}

.c-badge {
  font-size: 10px;
  font-weight: 700;
  min-width: 17px;
  padding: 5px 4px;
  border-radius: 100px;
  display: block;
  line-height: 0.7;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #f91605;
}

.c-badge--header-icon {
  position: absolute;
  bottom: -9px;
}

// CONFIGURACOES TOOLTIPS
.tooltip {
  width: 120px;
  z-index: 99999999999;
}

.tooltip-inner {
  padding: 8px 10px;
  color: #444;
  text-align: center;
  background-color: #dae6f6;
  font-size: 12px;
  border-radius: 32px;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #dae6f6 !important;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #dae6f6 !important;
}

.hamburger-toggle {
  position: relative;
  padding: 0;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  order: 1;
}

.hamburger-toggle [class*='bar-'] {
  display: block;
  background: $color-text-000;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .2s ease all;
  transition: .2s ease all;
  border-radius: 2px;
  height: 2px;
  width: 24px;
  margin-bottom: 4px;
}

.hamburger-toggle [class*='bar-']:nth-child(2) {
  width: 18px;
}

.hamburger-toggle [class*='bar-']:last-child {
  margin-bottom: 0;
  width: 12px;
}

.hamburger-toggle.is-opened {
  left: 3px;
}

.hamburger-toggle.is-opened [class*='bar-'] {
  background: $color-text-000;
}

.hamburger-toggle.is-opened .bar-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 15% 15%;
  transform-origin: 15% 15%;
}

.hamburger-toggle.is-opened .bar-mid {
  opacity: 0;
}

.hamburger-toggle.is-opened .bar-bot {
  -webkit-transform: rotate(45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 15% 95%;
  transform-origin: 15% 95%;
  width: 24px;
}

.hamburger-toggle:focus {
  outline-width: 0;
}

.hamburger-toggle:hover [class*='bar-'] {
  background: $color-text-000;
}

.header-icons-group {
  display: flex;
  order: 3;
  margin-left: auto;
  height: 100%;
  border-left: 1px solid #cccccc;
}

.header-icons-group .c-header-icon:last-child {
  border-right: 0;
}

.c-header-icon {
  position: relative;
  display: flex;
  float: left;
  width: 70px;
  height: 100%;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  border-right: 1px solid #cccccc;
}

.c-header-icon i {
  font-size: 18px;
  line-height: 40px;
}

.c-header-icon--in-circle {
  border: 1px solid #d0d0d0;
  border-radius: 100%;
}

.c-header-icon:hover i {
  color: #f5642d;
}

.l-header {
  padding-left: 70px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  background: #ffffff;
  -webkit-transition: padding 0.5s ease-in-out;
  -moz-transition: padding 0.5s ease-in-out;
  -ms-transition: padding 0.5s ease-in-out;
  -o-transition: padding 0.5s ease-in-out;
  transition: padding 0.5s ease-in-out;
}

.l-header__inner {
  height: 100%;
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: stretch;
  border-bottom: 1px solid;
  border-color: #cccccc;
}

.sidebar-is-expanded .l-header {
  padding-left: 220px;
}

.c-search {
  display: flex;
  height: 100%;
  width: 350px;
}

.c-search__input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0;
  flex-basis: 100%;
  height: 100%;
  border: 0;
  font-size: 14px;
  padding: 0 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.c-dropdown {
  opacity: 0;
  text-align: left;
  position: absolute;
  flex-direction: column;
  display: none;
  width: 300px;
  top: 30px;
  right: -40px;
  background-color: #fff;
  overflow: hidden;
  min-height: 300px;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
  -webkit-box-shadow: 0px 5px 14px -1px #cecece;
  -moz-box-shadow: 0px 5px 14px -1px #cecece;
  box-shadow: 0px 5px 14px -1px #cecece;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.l-sidebar {
  width: 70px;
  position: absolute;
  z-index: 1055;
  left: 0;
  top: 0;
  bottom: 0;
  background: $component-active-bg;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -ms-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.l-sidebar .logo {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $component-active-bg;
}

.l-sidebar .logo .logo__txt {
  border-radius: 10px;
  overflow: hidden;
  font-size: 26px;
  line-height: 1;
  color: #fff;
  text-align: center;
  font-weight: 700;
}

.l-sidebar__content {
  height: 100%;
  position: relative;

}

.sidebar-is-expanded .l-sidebar {
  width: 220px;
  overflow-y: auto;
  position: relative;
  @extend .scrollbar;

}

.c-menu>ul {
  display: flex;
  flex-direction: column;
}

.c-menu>ul .c-menu__item {
  color: #fff;
  max-width: 100%;
  overflow: hidden;
}

.c-menu>ul .c-menu__item__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 45px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.c-menu>ul .c-menu__item__inner2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.c-menu>ul .c-menu__item__inner:before {
  position: absolute;
  content: " ";
  height: 0;
  width: 2px;
  left: 0;
  top: 50%;
  margin-top: -18px;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}



.c-menu>ul .c-menu__item.is-active .c-menu__item__inner {
  border-left-color: #fff;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner i {
  color: none;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner .c-menu-item__title span {
  color: $component-active-bg !important;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner:before {
  height: 36px;
  opacity: 1;
  border-radius: 5px;
}

.c-menu>ul .c-menu__item:hover .text,.c-menu>ul .c-menu__item:hover i{
  color: $component-active-bg !important;
}

.c-menu>ul .c-menu__item.is-active2 .c-menu__item__inner {
  border-left-color: $component-active-bg;
}

.c-menu>ul .c-menu__item:hover .c-menu__item__inner:before {
  height: 45px;
  background: $component-active-bg !important;
  opacity: 0.25 !important;
  border-radius: 5px;
}

.c-menu>ul .c-menu__item.is-active2 .c-menu__item__inner i {
  color: none;
}

.c-menu>ul .c-menu__item.is-active2 .c-menu__item__inner2 .c-menu-item__title2 span {
  color: $color-bg-100;
}

.c-menu>ul .c-menu__item.is-active2 .c-menu__item__inner:before {
  height: 36px;
  opacity: 1;
}

/*.c-menu > ul .c-menu__item:not(.is-active):hover .c-menu__item__inner {
  background-color: $component-active-bg;
  border-left-color: $component-active-bg;
}*/
.c-menu>ul .c-menu__item i {
  flex: 0 0 70px;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.c-menu>ul .c-menu__item .c-menu-item__expand {
  position: relative;
  left: 100px;
  padding-right: 20px;
  margin-left: auto;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.sidebar-is-expanded .c-menu>ul .c-menu__item .c-menu-item__expand {
  left: 220px;
}

.c-menu>ul .c-menu__item .c-menu-item__title {
  flex-basis: 100%;
  padding-right: 10px;
  position: relative;
  left: 220px;
  opacity: 0;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.c-menu>ul .c-menu__item .c-menu-item__title span {
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.c-menu>ul .c-menu__item2 .c-menu-item__title2 span {
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sidebar-is-expanded .c-menu>ul .c-menu__item .c-menu-item__title {
  left: 0px;
  opacity: 1;
}

.c-menu>ul .c-menu__item .c-menu__submenu {
  background-color: transparent;
  padding: 1px;
  font-size: 12px;
  display: none;
}



.c-menu>ul .c-menu__item .c-menu__submenu li {
  padding-bottom: 1px;
  margin-bottom: 1px;
  border-bottom: 1px solid;
  border-color: transparent !important;
}

.c-menu>ul .c-menu__item .c-menu__submenu li:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}

.button_change_map_style {
  border: none;
  padding: 0px 3px;
  color: $widget-content-icon-color !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  background: transparent;
  margin: 4px 2px;
  cursor: pointer;
}

main.l-main {
  width: 100%;
  height: 100%;
  padding: 70px 0 0 70px;
  -webkit-transition: padding 0.5s ease-in-out;
  -moz-transition: padding 0.5s ease-in-out;
  -ms-transition: padding 0.5s ease-in-out;
  -o-transition: padding 0.5s ease-in-out;
  transition: padding 0.5s ease-in-out;
}

main.l-main .content-wrapper {
  padding: 25px;
  height: 100%;
}

main.l-main .content-wrapper .page-content {
  border-top: 1px solid #d0d0d0;
  padding-top: 25px;
}

main.l-main .content-wrapper--with-bg .page-content {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d0d0d0;
  padding: 25px;
}

main.l-main .page-title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 25px;
}

.sidebar-is-expanded main.l-main {
  padding-left: 220px;
}

#footer {
  background-color: $color-bg-000 !important;
  border-top: 1px solid $color-bg-000 !important;
  @include box-shadow(0 -1px 1px rgba(0, 0, 0, 0.08));
}

#global {
  width: 70px;
  margin: auto;
  margin-top: 300px;
  position: relative;
  cursor: pointer;
  height: 60px;
  text-align: center;
}

.mask {
  position: absolute;
  border-radius: 2px;
  overflow: hidden;
  perspective: 1000;
  backface-visibility: hidden;
}

.plane {
  background: $component-active-bg;
  width: 400%;
  height: 100%;
  position: absolute;
  transform: translate3d(0px, 0, 0);
  /*transition: all 0.8s ease; */
  z-index: 100;
  perspective: 1000;
  backface-visibility: hidden;

}

.animation {
  transition: all 0.3s ease;
}



#top .plane {
  z-index: 2000;
  animation: trans1 1.3s ease-in infinite 0s backwards;
}

#middle .plane {
  transform: translate3d(0px, 0, 0);
  background: $component-active-bg;
  animation: trans2 1.3s linear infinite 0.3s backwards;

}

#bottom .plane {
  z-index: 2000;
  animation: trans3 1.3s ease-out infinite 0.7s backwards;
}



#top {
  width: 53px;
  height: 20px;
  left: 20px;
  transform: skew(-15deg, 0);
  z-index: 100;
}

#middle {
  width: 33px;
  height: 20px;
  left: 20px;
  top: 15px;

  transform: skew(-15deg, 40deg)
}

#bottom {
  width: 53px;
  height: 20px;
  top: 30px;
  transform: skew(-15deg, 0)
}

p.hometown {
  color: $component-active-bg;
  position: absolute;
  left: -3px;
  top: 60px;
  text-align: center;
  font-size: 10px;
}

@keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0)
  }

  to {
    transform: translate3d(-250px, 0, 0)
  }
}

@keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0)
  }

  to {
    transform: translate3d(53px, 0, 0)
  }
}

@keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0)
  }

  to {
    transform: translate3d(-220px, 0, 0)
  }
}

@keyframes animColor {
  from {
    background: red;
  }

  25% {
    background: yellow;
  }

  50% {
    background: green;
  }

  75% {
    background: brown;
  }

  to {
    background: blue;
  }
}


.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .5s;
  border-radius: 30px;
  color: white;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #9da6b0;
  margin: 1px;
  color: #9da6b0;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  -ms-border-radius: 1000px;
  -o-border-radius: 1000px;
  border-radius: 1000px;
  font-size: 20px;
  text-align: center;
  padding: 5px;
  line-height: 3px;
}

.slider-zoom {
  -webkit-appearance: none;
  width: 70%;
  height: 9px;
  background-color: #9da6b0;
  border-radius: 10px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-zoom:hover {
  opacity: 1;
}

.slider-zoom::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 11px;
  width: 11px;
  margin-left: -3px;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
}

.slider-zoom::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.zoom_in {
  background-color: transparent;
  color: inherit;
  transition: all .5s;
  border-radius: 30px;
  color: white;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: none;
  margin: 1px;
  color: #9da6b0;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  -ms-border-radius: 1000px;
  -o-border-radius: 1000px;
  border-radius: 1000px;
  font-size: 25px;
  text-align: center;
  padding: 5px;
  line-height: 3px;
}

.zoom_out {
  background-color: transparent;
  color: inherit;
  transition: all .5s;
  border-radius: 30px;
  color: white;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: none;
  margin: 1px;
  color: #9da6b0;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  -ms-border-radius: 1000px;
  -o-border-radius: 1000px;
  border-radius: 1000px;
  font-size: 20px;
  text-align: center;
  padding: 5px;
  line-height: 3px;
}

.map-controls-bar-right {
  width: auto;
  height: 50px;
  top: 3px;
  right: 10px;
  position: absolute;
}

.sidebar-search-input {
  padding-left: 30px !important;
  background: $color-bg-000;
}

.user-active-badge {
  display: flex;
  height: 19px;
  background: $color-bg-000;
  font-size: 9px;
  border: $color-bg-300 1px solid;
  border-radius: 5px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);

}

.button-search-device {
  text-align: center;
  border-radius: 50%;
  background: $color-bg-000;
  @include box-shadow(0px 0px 15px rgba(0, 0, 0, .15));
  padding: 12px;
  width: 40px;
  height: 40px;
}

.device-avatar-objects-master {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
  border: $input-border-focus 1px solid;

}

.device-avatar-objects {
  font-size: 30px;
  height: 100%;
  padding-top: 25px;
  text-align: center;
  background: $color-bg-000;
  color: #d7d7d7 !important;
}

.device-avatar-objects-mini {
  background: $color-bg-000;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
  border: $input-border-focus 1px solid;
}

.device-avatar-online {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 0px;
  background: $color-bg-000;
  z-index: 999;
  padding: 1px 5px;
  border: $input-border-focus 1px solid;
  border-radius: 5px;
  text-align: center;
  font-size: 1.0rem;
  @include box-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
}

.avatar-text-name-mini {
  margin-top: 5px;
  color: $color-text-800;
  font-size: 12pt;
  font-weight: bold;

}

.avatar-text-name-mini-2 {
  font-size: 9pt;
  color: $color-text-800;
  font-weight: 400;
}

.avatar-address-device-mini-div {
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 10px;
  color: $color-text-800;
  font-size: 8pt !important;
  font-weight: 300;
}

.avatar-text-plate-mini {
  color: $color-text-800;
  font-size: 9pt;
  font-weight: 300;
  margin-top: -3pt;
}