#sms-form-modal {
    .card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        padding: 10px;
        box-sizing: border-box;
    }

    /* Aplica o padding somente em telas entre 768px e 1200px */
    @media (min-width: 768px) and (max-width: 1200px) {
        .modal-starred {
            //padding: 0px 50px;
        }
    }

    /* Adicione um estilo para quando o card estiver ativo */
    .card-sensors {
        cursor: pointer;
        /* Define o cursor como pointer */
    }

    .card-sensors.active {
        color: $component-active-bg !important;
        @include background-opacity($btn-primary-bg, 0.2);
    }

    /* Esconder o modal-footer por padrão */
    .modal-footer {
        display: none;
    }

    #data-container {
        margin: 10px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .container-message {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
    }


    .scrollable-messages-activation {
        overflow-y: scroll;
        max-height: calc(50vh - 100px);
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 98%;
    }

    .scrollable-messages-activation::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }


    .scrollable-messages-message {
        overflow-y: scroll;
        max-height: calc(50vh - 100px);
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 98%;
    }

    .scrollable-messages-message::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }


    .scrollable-messages-template {
        overflow-y: scroll;
        max-height: calc(50vh - 100px);
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 98%;
    }

    .scrollable-messages-template::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }



    .message {
        border-radius: 15px;
        padding: 10px 20px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        //width: 250px;
    }

    .message small {
        display: flex;
        align-items: center;
        margin-top: 5px;
        opacity: 0.8;
        font-size: 0.8em;
    }

    .message::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        top: 50%;
        transform: translateY(-50%);
    }

    .message.confirmed {
        background-color: #39A2DB;
    }

    .message.confirmed::before {
        border-width: 10px 10px 10px 0;
        border-color: transparent #39A2DB transparent transparent;
        left: -10px;
    }

    .message.sent {
        background-color: #39A2DB;
    }

    .message.sent::before {
        border-width: 10px 10px 10px 0;
        border-color: transparent #39A2DB transparent transparent;
        left: -10px;
    }

    .message.sending {
        background-color: #39A2DB;
    }

    .message.sending::before {
        border-width: 10px 10px 10px 0;
        border-color: transparent #39A2DB transparent transparent;
        left: -10px;
    }

    .message.failed {
        background-color: #d0021b;
    }

    .message.failed::before {
        border-width: 10px 10px 10px 0;
        border-color: transparent #d0021b transparent transparent;
        left: -10px;
    }

    .message.example {
        background-color: #00B894;
    }

    .message.example::before {
        border-width: 10px 10px 10px 0;
        border-color: transparent #00B894 transparent transparent;
        left: -10px;
    }

    .message.right.confirmed {
        background-color: #00B894;
    }

    .message.right.confirmed::before {
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #00B894;
        right: -10px;
        left: auto;
    }

    .message.right.sent {
        background-color: #39A2DB;
    }

    .message.right.sent::before {
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #39A2DB;
        right: -10px;
        left: auto;
    }

    .message.right.sending {
        background-color: #39A2DB;
    }

    .message.right.sending::before {
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #39A2DB;
        right: -10px;
        left: auto;
    }

    .message.right.failed {
        background-color: #d0021b;
    }

    .message.right.failed::before {
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #d0021b;
        right: -10px;
        left: auto;
    }

    .message.right.example {
        background-color: #00B894;
    }

    .message.right.example::before {
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #00B894;
        right: -10px;
        left: auto;
    }

    .icon {
        margin-right: 10px;
        font-size: 10px;
        display: inline-block;
    }

    .icon.check {
        width: 10px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    .icon.double-check {
        position: relative;
        width: 16px;
        height: 10px;
    }

    .icon.double-check::before,
    .icon.double-check::after {
        content: '';
        width: 10px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        position: absolute;
        transform: rotate(45deg);
    }

    .icon.double-check::before {
        left: 0;
        top: 0;
    }

    .icon.double-check::after {
        left: 6px;
        top: 0;
    }

    .icon.loading {
        border: 2px solid white;
        border-top: 2px solid transparent;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        animation: spin 1s linear infinite;
        position: relative;
        margin-right: 10px;
    }

    .icon.cross {
        position: relative;
        width: 12px;
        height: 12px;
    }

    .icon.cross::before,
    .icon.cross::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 12px;
        background-color: white;
        top: 0;
        left: 5px;
    }

    .icon.cross::before {
        transform: rotate(45deg);
    }

    .icon.cross::after {
        transform: rotate(-45deg);
    }

    /* Centering the messageContainer */
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        padding: 1px;
        width: 100%;

        border: 1px solid $color-border-100;

        border-radius: 8px;
        box-shadow: 0 0 4px $color-border-100;
    }



    .message-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        width: 100%;
    }

    .message-container .message.right {
        margin-left: auto;
    }

    .icon-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: 2px solid #ddd;
        border-radius: 50%;
        margin-right: 10px;
    }

    .ico-check:before,
    .icon.check:before,
    .icon.task:before,
    .icon.complete:before {
        content: none !important;
    }

    .starred-notfound-sms {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 400px;
    }

    .starred-notfound-sms i {
        color: #d9d9d9;
        font-size: 100px;
        margin: 0 auto;
    }

    .starred-notfound-title-sms {
        color: #334257;
        font-size: 16pt;
        font-weight: 700;
        margin: 16px 0;
    }

    .starred-notfound-info-sms {
        color: #666;
        font-weight: 300;
        text-align: center;
    }


}