#objects-page {

}
  
.scroll_invisible{
    /* width */
&::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
} 