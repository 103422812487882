.autocomplete-suggestions {
  border: 1px solid $dropdown-border;
  background: $dropdown-bg;
  overflow: auto;
}

.autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background: $dropdown-link-hover-bg;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: $dropdown-link-active-color;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid;
}