// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
  }

  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    //color: $badge-color;
    //background-color: $background;
  }
}

@mixin button-variant-light($color, $background, $border) {
  color: $component-active-bg;
  @include background-opacity($background, 0.2);
  border-color: $border;

  &:focus,
  &.focus {
    color: $component-active-bg;
    @include background-opacity($background, 0.2);
    //background-color: darken($background, 10%);
    //border-color: darken($border, 25%);
  }

  &:hover {
    color: $component-active-bg;
    @include background-opacity($background, 0.2);
    //background-color: darken($background, 10%);
    //border-color: darken($border, 12%);
  }

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    color: $component-active-bg;
    @include background-opacity($background, 0.2);
    //background-color: darken($background, 10%);
    //border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $component-active-bg;
      @include background-opacity($background, 0.2);
      //background-color: darken($background, 17%);
      //border-color: darken($border, 25%);
    }
  }

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      //background-color: $background;
      //border-color: $border;
      @include background-opacity($background, 0.2);
    }
  }

  .badge {
    color: $badge-color;
    background-color: $background
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}